import React,{useEffect} from 'react'
import Navbar from '../Components/Navbar';
import Shipping from './Home/Shipping';
import Footer from '../Components/Footer';

function Shippingpolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navbar />
            <br/>
            <br/>
            <br/>
            <br/>
            <>
                <div className="container mt-1">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='text-center mt-5 mb-5' style={{ fontWeight: '700' }}> Shipping Policy</h2>
                            <h3 className='mt-3 mb-3'>Return Shipping, Cancellation & Exchange Policy</h3>
                            <h4 className='mt-3 mb-3'> SHIPPING:</h4>
                            <p className='mt-3 mb-3'>Once you place your order with us, it can take 3-7 days to reach you (Depending upon your location)</p>
                            <h4 className='mt-3 mb-3'>CANCELLATION:</h4>
                            <p className='mt-3 mb-3'>As an organisation, we believe in 100% customer satisfaction. In case you wish to cancel an order due to any reason write to us at <a href="mailto:support@shopzabelo.com">support@shopzabelo.com</a> or whats-app us at +91-9216989165 numbers within 12 hours of placing an order.
                                NOTE: Orders cannot be cancelled/altered after 12 hours of placing the order. Once an order is packed/shipped, it cannot be cancelled and will be treated as a return if there is non-acceptance.
                            </p>
                        </div>
                    </div>
                </div>
            </>
            <Shipping />
            <Footer />
        </>
    )
}

export default Shippingpolicy