import React, { useEffect, useState } from "react";
import Dim from "../../Assets/Screenshot 2023-10-13 110103.png";
import axios from "axios";
import { SyncLoader } from "react-spinners";
import { GiShoppingCart } from "react-icons/gi";
import { FaRegHeart } from "react-icons/fa";
import { API_URL } from "../Url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import Col from "react-bootstrap/Col";
import { useMain } from "../../Hooks/useMain";

function TrendingProduct() {
  const navigate = useNavigate();

  const [Prodcuts, setProdcuts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { handleCartChange, handleWishlistChange } = useMain();

  useEffect(() => {
    const Get_All_products = async () => {
      try {
        const response = await axios.get(`${API_URL}/product/trending/get`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        setProdcuts(response.data.data.products);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    Get_All_products();
  }, []);

  const addtolive = (product) => {
    console.log(product, "aaaa");
  };

  // ===================[Add to Wishlist api]=======================//

  const AddtoWishlist = async (ProdcutId) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      toast.error("Please Login First", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const type = "add";
      try {
        const response = await axios.get(
          `${API_URL}/api/wishlist/product/${ProdcutId}/${type}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status == 200) {
          handleWishlistChange(
            response?.data?.data?.wishlist?.products?.length || 0
          );
          toast.success(response.data.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        toast.error(error.response.data.error.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // alert(error.response.data.error.message)
        console.error("Error fetching data:", error);
      }
    }
  };

  // ===================[Add to cart api]=======================//

  const Addtocartlist = async (ProdcutId) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      toast.error("Please Login First", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const type = "add";
      try {
        const response = await axios.post(
          `${API_URL}/user/cart/product/${ProdcutId?._id}/${type}`,
          {
            variant: ProdcutId?.priceVarient[0]?.varient,
            varientPrice: ProdcutId?.priceVarient[0]?.price,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status == 200) {
          handleCartChange(response?.data?.data?.cart?.products?.length || 0);
          toast.success(response.data.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        // alert(error.response.data.error.message)
        toast.error(error.response.data.error.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error("Error fetching data:", error);
      }
    }
  };

  const ProductClick = (item) => {
    navigate(`/ProductDetail/${encodeURIComponent(item?.displayName)}/${item._id}`, {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  };

  function addToCart(item) {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItemIndex = cart.findIndex(
      (cartItem) =>
        cartItem.variant === item?.priceVarient[0]?.varient &&
        cartItem.varientPrice === item?.priceVarient[0]?.price &&
        cartItem?.product?._id == item?._id
    );
    if (existingItemIndex !== -1) {
      cart[existingItemIndex].quantity++;
    } else {
      cart.push({
        product: item,
        quantity: 1,
        variant: item?.priceVarient[0]?.varient,
        varientPrice: item?.priceVarient[0]?.price,
      });
    }
    localStorage.setItem("cart", JSON.stringify(cart));

    // let cart = JSON.parse(localStorage.getItem("cart")) || [];
    // const isItemInCart = cart.some((cartItem) => cartItem?._id === item._id);

    // if (!isItemInCart) {
    //   // Check if item has quantity field, if not, set it to 1
    //   if (!item.quantity) {
    //     item.quantity = 1;
    //   }

    //   cart.push(item);
    //   localStorage.setItem("cart", JSON.stringify(cart));
    //   handleCartChange(cart.length || 0);
    //   toast.success("Product Added to Cart", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // } else {
    //   toast.error("Already Added in Cart", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // }
  }

  const Addtocartmain = (product) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      addToCart(product);
      toast.success("Product Added to Cart", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      Addtocartlist(product);
    }
  };

  function AddtoWishlists(item) {
    let Wishlist = JSON.parse(localStorage.getItem("Wishlist")) || [];
    const isItemInWishlist = Wishlist.some(
      (wishlistItem) => wishlistItem?._id === item._id
    );

    if (!isItemInWishlist) {
      Wishlist.push(item);
      localStorage.setItem("Wishlist", JSON.stringify(Wishlist));
      handleWishlistChange(Wishlist.length || 0);

      toast.success("Product Added to Wishlist", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("Already Added in Wishlist", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const AddtoWishlistmain = (product) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      AddtoWishlists(product);
      // toast.success("Product Added to wishlist", {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    } else {
      AddtoWishlist(product._id);
    }
  };

  return (
    <div className="trending-product" style={{ marginBottom: "70px" }}>
      <div className="text-center">
        {/* <img className="dim" src={Dim} alt="Dim" /> */}
        <h1>Trending Products</h1>
      </div>
      <div className="featuredproducts-cards">
        <div className="row">
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <span class="loader"></span>
            </div>
          ) : (
            Prodcuts?.map((product) => (
              <div className="col-6 col-md-4 col-lg-3 col-xl-3">
                <div
                  class="product-card"
                  style={{ width: "auto" }}
                  key={product?.id}
                >
                  <div className="featuredproducts-card-imgnew">
                    <img
                      onClick={() => {
                        ProductClick(product);
                      }}
                      src={product?.displayImage[0]?.url}
                      alt={`Product ${product?.id}`}
                      style={{
                        height: "185px",
                        padding: "14px 10px",
                        width: "158px",
                        objectFit: "cover",
                      }}
                    />

                    {/* <div
                      onClick={() => {
                        Addtocartmain(product);
                      }}
                      style={{
                        position: "absolute",
                        bottom: "85px",
                        right: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "35px",
                        height: "35px",
                        backgroundColor: "#ffff",
                        borderRadius: "50%",
                      }}
                    >
                      <GiShoppingCart />
                    </div> */}
                  </div>
                  <div
                    onClick={() => {
                      AddtoWishlistmain(product);
                    }}
                    className="wish-list"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "35px",
                      height: "35px",
                      backgroundColor: "#ffff",
                      borderRadius: "50%",
                    }}
                  >
                    <FaRegHeart />
                  </div>
                  <div className="product-card-content">
                    <h6>{product?.displayName?.slice(0, 25)}</h6>
                    <h5>Rs. {product?.price?.toFixed(2)}</h5>
                    <button
                      className="add-card-btn"
                      onClick={() => {
                        Addtocartmain(product);
                      }}
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="text-center">
        <button className="view-all-btn">View All</button>
      </div>
    </div>
  );
}
export default TrendingProduct;
