import React,{useEffect} from 'react'
import Shipping from './Home/Shipping'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'

function Termsandcondition() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <Navbar />
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <>
                <div className="container mt-1">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='text-center mt-5 mb-5' style={{ fontWeight: '700' }}>Terms & Conditions</h2>
                            <h3 className='mt-3 mb-3'>Amendments to Terms and Conditions</h3>
                            <p className='mt-3 mb-3'>ZABELO reserve the rights to amend the Terms and Conditions at any time without any prior notice. All amendments will be applicable immediately after the updates are made on this site. You are encouraged to visit the Terms and Conditions each time you sign up/sign in for our services and products to remain updated on the amendments, if any.</p>
                            <h3 className='mt-3 mb-3'>Online Account</h3>
                            <p className='mt-3 mb-3'>As a registered user, you are required to login to www.shopzabelo.com using your email id and a password of your choice. You will be solely responsible to keep your password confidential and safe. Any order placed from your login ID will be considered as order placed by you and will be fulfilled accordingly.</p>
                            <h3 className='mt-3 mb-3'>Terms of Operations</h3>
                            <p className='mt-3 mb-3'>www.shopzabelo.com in its sole discretion reserves the right to suspend or cancel any of its services at any time without any prior notice. All outstanding orders will, however, be fulfilled or fully refunded. www.shopzabelo.com reserves the rights to refuse services to anyone at any time without assigning any reasons for the decision. www.shopzabelo.com will not be responsible for any damage caused to anyone by the use of its website</p>
                            <h3 className='mt-3 mb-3'>Warranty and Liability:</h3>
                            <p className='mt-3 mb-3'>www.shopzabelo.com will not be responsible for any damage arising out of the use of the products shipped to the members or the products bought through “Zabelo”. We accept no liability for any delay or failure to perform our obligation under this Terms and Conditions if such a delay or failure is due to the circumstances beyond our reasonable control.</p>
                            <h3 className='mt-3 mb-3'> Copyright and Intellectual Property:</h3>
                            <p>All information displayed on the website is protected by copyright and other intellectual property laws. This site is designed, updated and managed independently by www.shopzabelo.com . You should not copy, modify, publish, transfer, sell, reproduce, distribute, display or in any way commercially exploit the content of www.shopzabelo.com failing which suitable legal actions will be taken under the jurisdiction of Thane Courts.</p>
                            <h3>
                            Damaged Products:
                            </h3>
                            <p>Our team carries out rigorous quality checks before shipping out the products to our customers. However, damages caused in transit after the product has been shipped is beyond our control. If you receive damaged product in your order, we will replace it with another piece of the same or similar kind based on our stocks. Kindly email us at support@shopzabelo.com to inform us about the damage along with its picture within 48 hours from the time you receive your order and we will explain you the next steps. We will not entertain any information about damage received after 48 hours from the time you received your order. </p>
                            <h3>Missed Delivery :</h3>
                            <p>Our logistics partners attempt thrice to deliver your shipment. However, for orders which are not collected at your end will be returned to us. We will re-ship the order at your request. All Cash-on-Delivery orders that are returned to us will be re-shipped only after the payment for the same is made online along with the additional shipping charges. </p>
                        </div>
                    </div>
                </div>
            </>
            <Shipping />
            <Footer />
        </>
    )
}

export default Termsandcondition