import React from 'react'
import amazon from '../../Assets/image 44.png'
import flipkart from '../../Assets/image 45.png'
import card from '../../Assets/Rectangle 7 (1).png';
function Offer() {
    return (
        <div className='offers' style={{marginBottom:'70px'}}>
            <div className='offer-card' data-aos="flip-left">
                <div className='offer-card-left' >
                    <h2 className='m-0'>90% Off Today</h2>
                    <h1 className='mb-4'>Buy Designer Bags</h1>
                    <button>Shop Now</button>
                    <img style={{ marginTop: '30px' }} src={amazon} />
                </div>
                <div className='offer-card-right' >
                    <img src={card} />
                </div>
            </div>
            <div className='offer-card offer-cardsss' data-aos="flip-right">
                <div className='offer-card-left'>
                    <h2 style={{ color: 'white' }}>90% Off Today</h2>
                    <h1 style={{ color: 'white' }} className='mb-4'>Buy Designer Bags</h1>
                    <button>Shop Now</button>
                    <img style={{ marginTop: '-60px' }} src={flipkart} />
                </div>
                <div className='offer-card-right'>
                </div>
            </div>
        </div>
    )
}

export default Offer