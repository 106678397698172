import React, { useEffect, useState } from 'react'
import { API_URL } from '../Url';
import axios from 'axios';

function Cosmetics() {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);
    const Get_All_banner = async () => {
        try {
            const response = await axios.get(`${API_URL}/banner3/all`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            console.log(response.data.data.banners, 'sss')
            setBanners(response.data.data.banners);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };
    useEffect(() => {
        Get_All_banner()
    }, [])
    if (banners?.length == 0) {
        return null
    }
    return (
        <>
            <a href={banners[0]?.link} target='_blank' style={{ textDecoration: "none", color: "black" }}>
                <div className='Upto'
                    style={{
                        backgroundImage: `url(${banners[0]?.bannerImage?.url})`,
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    <div className='upto-section' data-aos="zoom-in">
                        <h1 data-aos="zoom-in-up">Make up Cosmatics</h1>
                        <h2 data-aos="zoom-in-up">{banners[0]?.text}</h2>
                        <div className="d-flex justify-content-center">
                        <p style={{color: "#333333"}} data-aos="zoom-in-up">MEGA &nbsp;</p>
                        <p style={{color: "#001963"}} data-aos="zoom-in-up">SALE</p>
                        </div>
                    </div>
                </div>
            </a>
        </>
    )
}

export default Cosmetics