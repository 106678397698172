import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Shipping from './Home/Shipping'
import Wishlistproduct from './Home/Wishlistproduct'

function WishlistPage() {
  return (
    <>
    <Navbar/>
    <Wishlistproduct/>
    <Shipping/>
    <Footer/>
    </>
  )
}

export default WishlistPage