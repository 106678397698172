import React from "react";
import img1 from "../../Assets/Mask group.png";
import img2 from "../../Assets/Mask group-1.png";
import img3 from "../../Assets/Mask group (1).png";
import img4 from "../../Assets/Mask group (2).png";
function Shipping() {
  return (
    <div className="servics">
      <div className="servicescard">
        <img src={img1} alt="img" />
        <div className="free">
          <h4 className="services-title">Free Shipping</h4>
          <p className="services-text">Free shipping all order</p>
        </div>
      </div>
      <div className="servicescard">
        <img src={img2} alt="img" />
        <div className="free">
          <h4 className="services-title">Made in india </h4>
          <p className="services-text"> Made With Love</p>
        </div>
      </div>
      <div className="servicescard">
        <img src={img3} alt="img" />
        <div className="free">
          <h4 className="services-title">Money Return</h4>
          <p className="services-text">3 days for free return</p>
        </div>
      </div>
      <div className="servicescard">
        <img src={img4} alt="img" />
        <div className="free">
          <h4 className="services-title">100% Payment Secure</h4>
          <p className="services-text">We ensure secure payment</p>
        </div>
      </div>
    </div>
  );
}

export default Shipping;
