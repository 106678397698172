import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../Url";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";

function Modallogin({ loginToggle }) {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [otp, setOtp] = useState("");
  const [passFlag1, setPassFlag1] = useState(false);
  const [eamil1, setEmail1] = useState("");
  const [pass1, setPass1] = useState("");
  const navigate = useNavigate();

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/user/signin`, {
        email: email,
        password: password,
      });
      localStorage.setItem("UserID", response.data.data.user._id);
      localStorage.setItem("UserToken", response.data.data.user.token);
      toast.success(response.data.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(response.data);
      setemail("");
      setpassword("");
      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error.response.data.error.message);
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/user/forgot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: eamil1,
        }),
      });
      const res = await response.json();
      console.log(res);
      if (res?.status == "success") {
        toast.success("OTP send to Mail");
        setPassFlag1(true);
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/user/verifyotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: eamil1,
          otp: otp,
          password: pass1,
        }),
      });
      const res = await response.json();
      if (res?.status == "success") {
        toast.success("Password Update Successfully.");
        setForgotPasswordMode(false);
        setPassFlag1(false);
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <>
      <div className="w-100 mt-4">
          <div>
            <div className="add-title mb-3">
              {forgotPasswordMode ? "Forgot Password" : "Log in"}
            </div>
            {forgotPasswordMode ? (
              <>
                {!passFlag1 ? (
                  <Form onSubmit={handleForgotPassword}>
                    <Form.Group controlId="formOtp" className="mt-1">
                      <Form.Label className="label-title">Email</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-input-"
                        placeholder="Enter Email"
                        value={eamil1}
                        onChange={(e) => setEmail1(e.target.value)}
                      />
                    </Form.Group>
                    <Button
                      className="mt-4"
                      type="submit"
                      style={{ backgroundColor: "#4e2985", width: "40%" }}
                    >
                      Send OTP
                    </Button>
                  </Form>
                ) : (
                  <Form onSubmit={handleResetPassword}>
                    <Form.Group controlId="formOtp" className="mt-1">
                      <Form.Label className="label-title">OTP</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-input-"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group controlId="formNewPassword" className="mt-1">
                      <Form.Label className="label-title">
                        New Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        className="form-input-"
                        placeholder="Enter your new password"
                        value={pass1}
                        onChange={(e) => setPass1(e.target.value)}
                      />
                    </Form.Group>
                    <button
                      className="mt-4 Add-address-btn"
                    >
                      Reset Password
                    </button>
                  </Form>
                )}
              </>
            ) : (
              <Form onSubmit={handleLoginSubmit}>
                <Form.Group controlId="formName" className="mt-1">
                  <Form.Label className="label-title">Email</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-input-"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formPassword" className="mt-1">
                  <Form.Label className="label-title">Password</Form.Label>
                  <Form.Control
                    className="form-input-"
                    type="password"
                    placeholder="Enter your Password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </Form.Group>
                <button className="mt-4 Add-address-btn">
                  Login
                </button>
              </Form>
            )}
            <Card.Text className="text-center mt-3">
              {!forgotPasswordMode ? (
                <Link onClick={() => setForgotPasswordMode(true)}>
                  Forgot Password
                </Link>
              ) : (
                <Link onClick={() => setForgotPasswordMode(false)}>Login</Link>
              )}
            </Card.Text>
            <Card.Text className="text-center mt-3">
              Not have an Account?{" "}
              <Link onClick={() => loginToggle(false)}>Signup</Link>
            </Card.Text>
          </div>
      </div>
    </>
  );
}

export default Modallogin;
