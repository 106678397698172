import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import StorePage from './Pages/StorePage';
import ProductDetails from './Pages/ProductDetails';
import Cart from './Pages/Cart';
import CheckOut from './Pages/CheckOut';
import Profile from './Pages/Profile';
import Blog from './Pages/Blog';
import BlogDetails from './Pages/BlogDetails';
import AboutUs from './Pages/AboutUs';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react';
import PaymentFail from './Pages/PaymentFail';
import PaymentSucessful from './Pages/PaymentSucessful';
import Contactus from './Pages/Contactus';
import FAQ from './Pages/FAQ';
import TandP from './Pages/TandP';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DetailedProductpage from './Pages/DetailedProductpage';
import 'bootstrap/dist/css/bootstrap.min.css';
import WishlistPage from './Pages/WishlistPage';
import NewCart from './Pages/WithoutLogin/Newcart';
import Newwishlistproduct from './Pages/WithoutLogin/Newwishlistproduct';
import Privacypolicy from './Pages/Privacypolicy';
import Termsandcondition from './Pages/Termsandcondition';
import ReturnPolicy from './Pages/ReturnPolicy';
import Shippingpolicy from './Pages/Shippingpolicy';
import CategoryPage from './Pages/CategoryPage';


function App() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, [])

  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ProductDetail/:productName/:id" element={<DetailedProductpage />} />
          <Route path="/shop" element={<StorePage />} />
          <Route path="/category/:catName/:categoryId" element={<CategoryPage />} />
          <Route path="/shop/:id" element={<ProductDetails />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Wishlist" element={<WishlistPage />} />
          <Route path="/blogdetail/:id" element={<BlogDetails />} />
          <Route path="/about" element={<AboutUs />} />
          {/* <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} /> */}
          <Route path="/failed" element={<PaymentFail />} />
          <Route path="/sucess" element={<PaymentSucessful />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/policy" element={<TandP />} />
          <Route path="/carts" element={<NewCart/>} />
          <Route path="/wishlists" element={<Newwishlistproduct/>} />
          <Route path="/Privacy_policy" element={<Privacypolicy/>} />
          <Route path="/Terms&Conditions" element={<Termsandcondition/>} />
          <Route path="/returenPolicy" element={<ReturnPolicy/>} />
          <Route path="/Shippingpolicy" element={<Shippingpolicy/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
