import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Shipping from "./Home/Shipping";
import { useLocation, useParams } from "react-router";
import { API_URL } from "./Url";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import ReactImageMagnify from "react-image-magnify";
// import {SideBySideMagnifier} from "react-image-magnifiers";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css"; // Import Swiper styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import leftArrow from "../Assets/left-arrow.png";
import rightArrow from "../Assets/right-arrow.png";
import { FaRegHeart } from "react-icons/fa";
import Slider from "react-slick";
import "react-toastify/dist/ReactToastify.css";
import ImageMagnifier from "../Components/ImageMagnifier";

function DetailedProductpage() {
  const {id} = useParams()
  console.log(id);
  let location = useLocation();
  const [productdata, setProductData] = useState(location?.state?.item||null)
  console.log(productdata);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [image, setimage] = useState(0);
  const [activePriceVarient, setActivePriceVarient] = useState(null);
  const [categoryProd, setCategoryProd] = useState([]);
  const [loading, setLoading] = useState(false);
  let sliderRef = useRef(null);

  useEffect(() => {
    getProductByCategory();
    getProduct()
  }, [productdata, id]);

  const getProduct = async()=>{
    try {
      const res = await axios.get(`${API_URL}/product/${id}`)
      if(res?.status==200){
        setProductData(res?.data?.data?.product);
      }
    } catch (error) {
      
    }
  }

  const getProductByCategory = async () => {
    try {
      const catId =
        productdata?.product_category?._id || productdata?.product_category;
      if (catId) {
        const res = await axios.get(`${API_URL}/product/category/${catId}`);
        console.log(res);
        if (res?.status === 200) {
          setCategoryProd(res?.data?.data?.products);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const next = () => {
    sliderRef.slickNext();
  };

  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    infinite: true,
    slidesToShow: 5,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
  };

  const ProductClick = (item) => {
    navigate(`/ProductDetail/${encodeURIComponent(item?.displayName)}/${item._id}`, {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  };
  const imagechange = (index) => {
    setimage(index);
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  useEffect(() => {
    if (
      productdata &&
      productdata.priceVarient &&
      productdata.priceVarient.length > 0
    ) {
      setActivePriceVarient(productdata.priceVarient[0]);
    }
  }, [productdata]);

  const getButtonClassName = (variant) => {
    return `quantity-container ${activePriceVarient == variant ? "active-2" : ""
      }`;
  };

  console.log(activePriceVarient);
  const Addtocartlist = async (ProdcutId, quantity) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      toast.error("Please Login First", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const type = "add";
      try {
        const response = await axios.post(
          `${API_URL}/user/cart/product/${ProdcutId}/${type}?quantity=${quantity}`,
          {
            variant: activePriceVarient?.varient,
            varientPrice: activePriceVarient?.price,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(response.data.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } catch (error) {
        toast.error(error.response.data.error.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error("Error fetching data:", error);
      }
    }
  };

  function addToCart(item) {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    // cart.push(item);
    const existingItemIndex = cart.findIndex(
      (cartItem) =>
        cartItem.variant === activePriceVarient?.varient &&
        cartItem.varientPrice === activePriceVarient?.price &&
        cartItem?.product?._id == item?._id
    );
    if (existingItemIndex !== -1) {
      cart[existingItemIndex].quantity++;
    } else {
      cart.push({
        product: item,
        quantity: 1,
        variant: activePriceVarient?.varient,
        varientPrice: activePriceVarient?.price,
      });
    }
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  const Addtocartmain = (product, quantity) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      addToCart(product);
      toast.success("Product Added to Cart", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      Addtocartlist(product._id, quantity);
    }
  };

  const handleVariantClick = (variant) => {
    setActivePriceVarient(variant);
  };

  const swiperParams = {
    slidesPerView: 3,
    spaceBetween: 20,
    breakpoints: {
      // When window width is <= 640px
      640: {
        slidesPerView: 1,
      },
      // When window width is <= 768px
      768: {
        slidesPerView: 2,
      },
      // When window width is <= 1024px
      1024: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <>
      <Navbar />
      <div className="container profileFont" style={{marginTop: "6.5rem", important:true}}>
        <div className="row">
          <div className="col-lg-6 mt-5 pl-left">
            {productdata ? (
              <>
                {/* <SideBySideMagnifier
                  imageSrc={productdata?.displayImage[image]?.url}
                  imageAlt={productdata?.displayName}
                  largeImageSrc={productdata?.displayImage[image]?.ur}
                /> */}
                {/* <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: productdata?.displayName,
                      isFluidWidth: true,
                      src: productdata?.displayImage[image]?.url,
                    },
                    largeImage: {
                      src: productdata?.displayImage[image]?.url,
                      width: 1200,
                      height: 1800,
                    },
                    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 },
                  }}
                /> */}
                {/* <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: productdata?.displayName,
                      isFluidWidth: true,
                      src: productdata?.displayImage[image]?.url,
                    },
                    largeImage: {
                      src: productdata?.displayImage[image]?.url,
                      width: 1200,
                      height: 1800,
                    },
                    enlargedImageContainerStyle: {
                      background: "#fff",
                      zIndex: 9,
                      position: "absolute",
                      top: 0,
                      left: -10,
                      width: "100%",
                      height: "100%",
                    },
                  }}
                /> */}
                <img className='mainimg' src={productdata?.displayImage[image]?.url}  />
              </>
            ) : (
              <p>Loading...</p>
            )}
            <div className="second-img mt-5">
              {productdata?.displayImage?.slice(0, 6).map((product, index) => (
                <img
                  onClick={() => {
                    imagechange(index);
                  }}
                  key={index}
                  className="img-thumbnail mr-2"
                  height={100}
                  width={100}
                  src={product.url}
                  alt={`Thumbnail ${index + 1}`}
                />
              ))}
            </div>
          </div>
          <div className="col-lg-6 mt-5">
            {productdata ? (
              <div>
                <h1 className="detail-title">{productdata?.displayName}</h1>
                <h2 className="detail-title">
                  Rs. {activePriceVarient?.price}
                </h2>
                <p className="detail-text">
                  <span style={{ fontWeight: "bolder" }}>Color </span>
                  {productdata?.color?.color_name}
                </p>
                <div
                  style={{
                    backgroundColor: `${productdata?.color?.hexcode}`,
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "1px solid black",
                  }}
                ></div>
                {productdata?.priceVarient &&
                  productdata.priceVarient.length > 0 && (
                    <div>
                      {/* <h3>Variants:</h3> */}
                      <br />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "12px",
                        }}
                      >
                        {" "}
                        <div className="quantity-container">
                          <button
                            className="quantity-btn"
                            onClick={decreaseQuantity}
                          >
                            -
                          </button>
                          <p className="m-0">{quantity}</p>
                          <button
                            className="quantity-btn"
                            onClick={increaseQuantity}
                          >
                            +
                          </button>
                        </div>
                        <button
                          onClick={() => {
                            Addtocartmain(productdata, quantity);
                          }}
                          className="quantity-btns"
                        >
                          Add to cart
                        </button>
                        {productdata.priceVarient.map((variant, index) => (
                          <button
                            key={index}
                            className="price-btn"
                            onClick={() => handleVariantClick(variant)}
                          >
                            {/*  {variant.varient}  */} Rs. {variant.price}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                <br />
                <p
                  dangerouslySetInnerHTML={{ __html: productdata?.description }}
                ></p>
                {/* varient add here */}
                {/* <h3>Color</h3> */}
                {/* <div className="row mt-3">
                  <div className="col-sm-4">
                   
                  </div>
                  <div className="col-sm-4">
                  </div>
                </div> */}
                {/* <div className="mt-3">
                                    <h4>Category: {productdata.product_category.name}</h4>
                                </div> */}
              </div>
            ) : null}
          </div>
        </div>
        <div className="featuredproducts" style={{ marginBottom: "70px" }}>
          {
            categoryProd && categoryProd?.length > 0 &&
            <div >
              <div className="d-flex justify-content-between">
                <h1>Related Products</h1>
                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                  <button
                    className="button"
                    onClick={previous}
                    style={{ border: "none", background: "transparent" }}
                  >
                    <img src={leftArrow} alt="" />
                  </button>
                  <button
                    className="button"
                    onClick={next}
                    style={{ border: "none", background: "transparent" }}
                  >
                    <img src={rightArrow} alt="" />
                  </button>
                </div>
              </div>
              <br />
              <br />
              <div className="featuredproducts-cards">
                <Slider
                  ref={(slider) => {
                    sliderRef = slider;
                  }}
                  {...settings}
                >
                  {categoryProd?.map((product) => (
                    <div class="product-card">
                      <div  key={product?.id}>
                        <div className="featuredproducts-card-imgnew">
                          <img
                            onClick={() => {
                              ProductClick(product);
                            }}
                            src={product?.displayImage[0]?.url}
                            alt={`Product ${product?.id}`}
                            style={{
                              height: "300px",
                              padding: "14px 10px",
                              width: "98%",
                              objectFit: "cover",
                            }}
                          />
                          {/* <div
                          className="wish-list"
                          onClick={() => {
                            // AddtoWishlistmain(product);
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "35px",
                            height: "35px",
                            backgroundColor: "#ffff",
                            borderRadius: "50%",
                          }}
                        >
                          <FaRegHeart />
                        </div> */}
                        </div>
                        <div className="product-card-content">
                          <h6>{product?.displayName?.slice(0, 20)}</h6>
                          <h5>Rs. {product?.price?.toFixed(2)}</h5>
                          {/* <button
                        className="add-card-btn"
                        onClick={() => {
                          Addtocartmain(product);
                        }}
                      >
                        Add To Cart
                      </button> */}
                        </div>
                      </div>
                    </div>
                  ))
                  }

                </Slider>
              </div>
            </div>
          }
        </div>
      </div>
      <Shipping />
      <Footer />
    </>
  );
}

export default DetailedProductpage;
