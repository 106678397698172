import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { IoIosArrowForward } from "react-icons/io";
import { API_URL } from "./Url";
import Shipping from "./Home/Shipping";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import { SyncLoader } from "react-spinners";
import { GiShoppingCart } from "react-icons/gi";
import { FaRegHeart } from "react-icons/fa";
import { Button, Form, Pagination } from "react-bootstrap";
import "./Store.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useMain } from "../Hooks/useMain";
import { useParams } from "react-router-dom";

function CategoryPage() {
  const navigate = useNavigate();
  const { categoryId, catName } = useParams();
  console.log(categoryId, catName);
  const [Prodcuts, setProdcuts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(15);
  const [searchTerm, setSearchTerm] = useState("");
  const [Category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { handleWishlistChange, handleCartChange } = useMain();
  const [minPrice, setMinPrice] = useState(350);
  const [maxPrice, setMaxPrice] = useState("");
  const [featured, setFeatured] = useState(false);
  const [trending, setTrending] = useState(false);
  const [sortBy, setSortBy] = useState("");

  const filterProducts = () => {
    let filtered = [...Prodcuts];

    if (selectedCategory !== "") {
      filtered = filtered.filter(
        (product) =>
          product?.product_category?.name?.toLowerCase() ===
          selectedCategory?.toLowerCase()
      );
    }

    if (minPrice !== "" && maxPrice !== "") {
      filtered = filtered.filter(
        (product) =>
          product?.price >= parseFloat(minPrice) &&
          product?.price <= parseFloat(maxPrice)
      );
    }

    if (featured === true) {
      filtered = filtered.filter((product) => product?.isfeatured);
    }

    if (trending === true) {
      filtered = filtered.filter((product) => product?.istrending);
    }
    if (sortBy == "lowToHigh") {
      filtered.sort((a, b) => a.price - b.price);
    } else if (sortBy == "highToLow") {
      filtered.sort((a, b) => b.price - a.price);
    }

    setFilteredProducts(filtered);
  };

  useEffect(() => {
    filterProducts();
  }, [minPrice, maxPrice, trending, featured, sortBy]);

  const clearFilters = () => {
    // setSelectedCategory("");
    setSearchTerm("");
    setMinPrice("");
    setMaxPrice("");
    setFeatured(false);
    setTrending(false);
    setFilteredProducts(Prodcuts);
    setSortBy("");
  };

  useEffect(() => {
    const Get_All_products = async (id) => {
      try {
        const response = await axios.get(`${API_URL}/product/category/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        setProdcuts(response.data.data.products);
        setFilteredProducts(response.data.data.products);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    if (categoryId) {
      Get_All_products(categoryId);
    }
  }, []);

  //   const handleCategoryChange = (event) => {
  //     const category = event.target.value;
  //     setSelectedCategory(category);
  //     setCurrentPage(1);

  //     if (category) {
  //       const filtered = Prodcuts.filter(
  //         (product) => product?.product_category?.name === category
  //       );
  //       setFilteredProducts(filtered);
  //     } else {
  //       setFilteredProducts(Prodcuts);
  //     }
  //     console.log(Prodcuts)
  //   };

  //   useEffect(() => {
  //     const Get_All_categerys = async () => {
  //       try {
  //         const response = await axios.get(`${API_URL}/product/category/all`, {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Accept: "application/json",
  //           },
  //         });
  //         setCategory(response.data.data.categories);
  //         setLoading(false);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //         setLoading(false);
  //       }
  //     };

  //     Get_All_categerys();
  //   }, []);
  //======================[ add to wishlist ] ===========================

  const AddtoWishlist = async (ProdcutId) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      toast.error("Please Login First", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const type = "add";
      try {
        const response = await axios.post(
          `${API_URL}/user/cart/product/${ProdcutId?._id}/${type}`,
          {
            variant: ProdcutId?.priceVarient[0]?.varient,
            varientPrice: ProdcutId?.priceVarient[0]?.price,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // alert(response.data.data.message);
        if (response.status == 200) {
          handleWishlistChange(
            response?.data?.data?.wishlist?.products?.length || 0
          );
          toast.success(response.data.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        // alert(error.response.data.error.message)
        toast.error(error.response.data.error.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error("Error fetching data:", error);
      }
    }
  };

  // ===================[Add to cart api]=======================//
  const Addtocartlist = async (ProdcutId) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      toast.error("Please Login First", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const type = "add";
      try {
        const response = await axios.post(
          `${API_URL}/user/cart/product/${ProdcutId?._id}/${type}`,
          {
            variant: ProdcutId?.priceVarient[0]?.varient,
            varientPrice: ProdcutId?.priceVarient[0]?.price,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status == 200) {
          handleCartChange(response?.data?.data?.cart?.products?.length || 0);
          toast.success(response.data.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        // alert(error.response.data.error.message)
        toast.error(error?.response?.data?.error?.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error("Error fetching data:", error);
      }
    }
  };

  const ProductClick = (item) => {
    navigate(`/ProductDetail/${encodeURIComponent(item?.displayName)}/${item._id}`, {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  };
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);

    // Filter products based on the search term
    const filtered = Prodcuts.filter((product) =>
      product.displayName.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFilteredProducts(filtered);
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  function addToCart(item) {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItemIndex = cart.findIndex(
      (cartItem) =>
        cartItem.variant === item?.priceVarient[0]?.varient &&
        cartItem.varientPrice === item?.priceVarient[0]?.price &&
        cartItem?.product?._id == item?._id
    );
    if (existingItemIndex !== -1) {
      cart[existingItemIndex].quantity++;
    } else {
      cart.push({
        product: item,
        quantity: 1,
        variant: item?.priceVarient[0]?.varient,
        varientPrice: item?.priceVarient[0]?.price,
      });
    }
    localStorage.setItem("cart", JSON.stringify(cart));

    // let cart = JSON.parse(localStorage.getItem("cart")) || [];
    // const isItemInCart = cart.some((cartItem) => cartItem?._id === item._id);

    // if (!isItemInCart) {
    //   // Check if item has quantity field, if not, set it to 1
    //   if (!item.quantity) {
    //     item.quantity = 1;
    //   }

    //   cart.push(item);
    //   localStorage.setItem("cart", JSON.stringify(cart));
    //   handleCartChange(cart.length || 0);
    //   toast.success("Product Added to Cart", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // } else {
    //   toast.error("Already Added in Cart", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // }
  }

  const Addtocartmain = (product) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      addToCart(product);
      toast.success("Product Added to Cart", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      Addtocartlist(product);
    }
  };

  function AddtoWishlists(item) {
    let Wishlist = JSON.parse(localStorage.getItem("Wishlist")) || [];
    const isItemInWishlist = Wishlist.some(
      (wishlistItem) => wishlistItem?._id === item._id
    );

    if (!isItemInWishlist) {
      Wishlist.push(item);
      localStorage.setItem("Wishlist", JSON.stringify(Wishlist));
      handleWishlistChange(Wishlist.length || 0);

      toast.success("Product Added to Wishlist", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("Already Added in Wishlist", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const AddtoWishlistmain = (product) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      AddtoWishlists(product);
      // toast.success("Product Added to wishlist", {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    } else {
      AddtoWishlist(product);
    }
  };

  return (
    <>
      <Navbar />
      {/* <div className="shopbanner" data-aos="zoom-in">
        <h1>Shop</h1>
        <p>
          Home <IoIosArrowForward /> Shop
        </p>
      </div> */}
      {/* <div
        style={{ marginTop: "50px" }}
        className="d-flex justify-content-center align-items-center"
      > */}
      <Form className="w-100">
        <div className="filter-section">
          {/* <Form.Select
              className="me-2"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="">Select</option>
              {Category.map((category, index) => (
                <option key={index} value={category?.name}>
                  {category?.name}
                </option>
              ))}
            </Form.Select> */}

          <Form.Control
            className="form-control select-options me-2"
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={handleSearch}
            // style={{ width: '400px' }}
          />

          <Form.Group className="d-flex align-items-center sm:w-100 lg:w-50">
            <Form.Label style={{ color: "#FFF", width: "140px" }}>
              Price Range:
            </Form.Label>
            <div className="price-range-container">
              {/* <span>₹{minPrice}</span> */}
              {/* <input
                  type="range"
                  min="350"
                  max="5000"
                  step="1"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                /> */}
              <span style={{ color: "#FFF", margin: "0 20px" }}>
                ₹{maxPrice}
              </span>
              <input
                type="range"
                min="350"
                max="5000"
                step="1"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </div>
          </Form.Group>

          {/* Featured and Trending checkboxes */}
          <div className="d-flex align-items-center sm:justify-content-between justify-content-around select-filter">
            <Form.Check
              type="checkbox"
              label="Featured"
              style={{ color: "#FFF" }}
              checked={featured}
              onChange={(e) => setFeatured(e.target.checked)}
            />
            <Form.Check
              type="checkbox"
              label="Trending"
              style={{ color: "#FFF" }}
              checked={trending}
              onChange={(e) => setTrending(e.target.checked)}
            />
          </div>
          <Form.Group className="select-options  md:d-flex">
            <Form.Label style={{ color: "#FFF" }}>Sort By:</Form.Label>
            <Form.Select
              // className="me-2"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="">Select</option>
              <option value="lowToHigh">Price: Low to High</option>
              <option value="highToLow">Price: High to Low</option>
            </Form.Select>
          </Form.Group>
          {/* Clear Filters button */}
          <button className="clear-btn " onClick={clearFilters}>
            Clear Filters
          </button>
        </div>
      </Form>
      {/* </div> */}

      <div className="container ">
        <div className="row w-100">
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <span class="loader"></span>
            </div>
          ) : (
            currentProducts?.map((product) => (
              <div
                key={product.id}
                className="col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div class="product-card" style={{ width: "100%" }}>
                  <div className="featuredproducts-card-imgnew">
                    <img
                      onClick={() => {
                        ProductClick(product);
                      }}
                      src={product?.displayImage[0]?.url}
                      alt={`Product ${product?.id}`}
                      style={{
                        height: "300px",
                        padding: "14px 10px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />

                    {/* <div
                        onClick={() => {
                          Addtocartmain(product);
                        }}
                        style={{
                          position: "absolute",
                          bottom: "85px",
                          right: "60px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "35px",
                          height: "35px",
                          backgroundColor: "#ffff",
                          borderRadius: "50%",
                        }}
                      >
                        <GiShoppingCart />
                      </div>
                      <div
                        onClick={() => {
                          AddtoWishlistmain(product);
                        }}
                        style={{
                          position: "absolute",
                          bottom: "85px",
                          right: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "35px",
                          height: "35px",
                          backgroundColor: "#ffff",
                          borderRadius: "50%",
                        }}
                      >
                        <FaRegHeart />
                      </div> */}
                  </div>
                  <div
                    onClick={() => {
                      AddtoWishlistmain(product);
                    }}
                    className="wish-list"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "35px",
                      height: "35px",
                      backgroundColor: "#ffff",
                      borderRadius: "50%",
                    }}
                  >
                    <FaRegHeart />
                  </div>
                  <div className="product-card-content">
                    <h5 className="text-center">
                      Rs. {product?.price?.toFixed(2)}
                    </h5>
                    <h6 className="text-center">
                      {product?.displayName?.slice(0, 25)}
                    </h6>
                    <button
                      className="add-card-btn"
                      onClick={() => {
                        Addtocartmain(product);
                      }}
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="pagination">
        <button className="page-btn" onClick={() => paginate(currentPage - 1)}>
          Prev
        </button>
        <p className="d-flex m-0 gap-4">
          {[
            ...Array(
              Math.ceil(filteredProducts.length / productsPerPage)
            ).keys(),
          ].map((number) => (
            <p
              className={
                number + 1 === currentPage ? "active-page" : "page-number"
              }
              onClick={() => paginate(number + 1)}
            >
              {number + 1}
            </p>
          ))}
        </p>
        <button className="page-btn" onClick={() => paginate(currentPage + 1)}>
          Next
        </button>
      </div>
      {/* <Pagination className="mt-3 d-flex align-item-center justify-content-center">
        {[
          ...Array(Math.ceil(filteredProducts.length / productsPerPage)).keys(),
        ].map((number) => (
          <Pagination.Item
            key={number + 1}
            active={number + 1 === currentPage}
            onClick={() => paginate(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination> */}
      <Shipping />
      <Footer />
    </>
  );
}

export default CategoryPage;
