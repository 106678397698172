// import React, { useState } from 'react';
// import loginImg from '../../Assets/banner-3 1.png';
// import { Button } from 'react-bootstrap';
// import Modallogin from './Modallogin';
// import ModalSignup from './ModalSignup';
// import { Form, Container, Row, Col, Card } from 'react-bootstrap';
// export default function LoginModal() {
//     const [isLogin, setIsLogin] = useState(true);

//     return (
//         <div className="container-fluid" style={{ borderRadius: '20px' }}>
//             <div className="row">
//                 <div className="col-sm-12 col-lg-6 d-flex align-items-center justify-content-center px-0 mt-4 mb-4">
//                     <img className="img-fluid" src={loginImg} alt="login/register" />
//                 </div>
//                 <div className="col-sm-12 col-lg-6 d-flex align-items-center justify-content-center pt-3">
//                     <div className="d-flex flex-column align-items-center">
//                         <div>
//                             <Card>
//                                 <Card.Body>
//                                     <Row style={{marginLeft:'30px'}}>
//                                         <Col>
//                                             <Button style={{ backgroundColor: '#4e2985' }} onClick={() => setIsLogin(true)}>Login</Button>
//                                         </Col>
//                                         <Col>
//                                             <Button style={{ backgroundColor: '#4e2985' }}onClick={() => setIsLogin(false)}>Signup</Button>
//                                         </Col>
//                                     </Row>
//                                 </Card.Body>
//                             </Card>
//                         </div>
//                         {isLogin ? <Modallogin /> : <ModalSignup />}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

import React, { useState } from "react";
import loginImg from "../../Assets/banner-3 1.png";
import { Button } from "react-bootstrap";
import Modallogin from "./Modallogin";
import ModalSignup from "./ModalSignup";
import { Form, Container, Row, Col, Card } from "react-bootstrap";

export default function LoginModal() {
  const [isLogin, setIsLogin] = useState(true);

  const loginToggle = (val) => {
    setIsLogin(val);
  };

  return (
    <div className="container-fluid" style={{ borderRadius: "20px" }}>
      <div className="row">
        <div className="col-sm-12 col-lg-6 d-flex align-items-center justify-content-center px-0">
          <img
            className="img-fluid"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            src={loginImg}
            alt="login/register"
          />
        </div>
        <div className="col-sm-12 col-lg-6 pt-3 px-4">
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="text-center d-flex align-items-center"
              style={{
                backgroundColor: isLogin ? "#001963" : "white",
                color: isLogin ? "white" : "black",
                borderRadius: "12px",
                fontFamily: "Poppins",
                width: "55%",
                padding: "10px 20px",
              }}
              onClick={() => setIsLogin(true)}
            >
              <h6 className="m-0" style={{ fontFamily: "Poppins" }}>
                Login
              </h6>
            </div>

            <div
              className="text-center d-flex align-items-center"
              style={{
                backgroundColor: isLogin ? "white" : "#001963",
                color: isLogin ? "black" : "white",
                borderRadius: "12px",
                fontFamily: "Poppins",
                width: "60%",
                padding: "10px 20px",
              }}
              onClick={() => setIsLogin(false)}
            >
              <h6 className="m-0" style={{ fontFamily: "Poppins" }}>
                Signup
              </h6>
            </div>
          </div>
          {isLogin ? (
            <Modallogin loginToggle={loginToggle} />
          ) : (
            <ModalSignup loginToggle={loginToggle} />
          )}
        </div>
      </div>
    </div>
  );
}
