import React from "react";
import Dim from "../../Assets/Screenshot 2023-10-13 110103.png";

function Explore() {
  return (
    <div className="popular">
      {/* <img className="dim" src={Dim} />
      <br /> */}
      <h1>Explore Popular Bags & Makeups</h1>

      <br />
      <br />
      <br />
      <br />
      <div className="grid">
        <div className="grid-left">
          <button className="new-btn">New</button>
          <div className="grid-1">
            <h3>face Cream</h3>
            <p>Explore now</p>
          </div>
        </div>
        <div className="grid-right">
          <div className="grid-right-part1">
            <div className="grid-2">
              <h3>
                Here the new
                <br /> collection
              </h3>
              <p>Explore now</p>
            </div>
          </div>
          <div className="grid-right-part2">
            <button className="new-btn" style={{ top: "20px" }}>
              New
            </button>
            <div className="grid-3">
              <h3>
                New Colloection <br />
                Bags
              </h3>
              <p>Explore now</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Explore;
