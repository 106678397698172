import React, { useState } from "react";
import logo from "../../Assets/image 68.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../Url";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";

function ModalSignup({ loginToggle }) {
  const [displayName, setdisplayName] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");

  const navigate = useNavigate();

  const handlesignupSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/user/signup`, {
        displayName: displayName,
        email: email,
        password: password,
        phoneNumber: phoneNumber,
      });
      // alert(response.data.data.message);
      toast.success(response.data.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      localStorage.setItem("UserID", response.data.data.user._id);
      localStorage.setItem("UserToken", response.data.data.user.token);
      console.log(response.data);
      navigate("/");
      setemail("");
      setdisplayName("");
      setpassword("");
      setphoneNumber("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error.response.data.error.message);
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // alert(error.response.data.error.message)
    }
  };

  return (
    <>
      <div className="w-100 mt-2">
          <div>
            <div className="add-title mb-3">Sign Up</div>
            <Form onSubmit={handlesignupSubmit}>
              <Form.Group controlId="formName" className="mt-1">
                <Form.Label className="label-title">Full Name</Form.Label>
                <Form.Control
                className="form-input-"
                  type="text"
                  placeholder="Enter your name"
                  value={displayName}
                  onChange={(e) => setdisplayName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-1">
                <Form.Label className="label-title">Email Address</Form.Label>
                <Form.Control
                className="form-input-"
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formPassword" className="mt-1">
                <Form.Label className="label-title">Password</Form.Label>
                <Form.Control
                className="form-input-"
                  type="password"
                  placeholder="Enter your Password"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formnumber" className="mt-1">
                <Form.Label className="label-title">Number</Form.Label>
                <Form.Control
                className="form-input-"
                  type="Number"
                  placeholder="Number"
                  value={phoneNumber}
                  onChange={(e) => setphoneNumber(e.target.value)}
                />
              </Form.Group>

              <button
                className="mt-4 Add-address-btn"
              >
                Sign Up
              </button>
            </Form>

            <Card.Text className="text-center mt-4 mb-4">
              Already have an Account?{" "}
              <Link onClick={() => loginToggle(true)}>Log in</Link>
            </Card.Text>
          </div>
      </div>
    </>
  );
}

export default ModalSignup;
