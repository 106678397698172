import React from "react";
import logo from "../Assets/WhatsApp_Image_2023-09-23_at_14.42 3.png";
import social from "../Assets/Group 235.png";
import downArrow from "../Assets/top-circle.svg";
import { Link } from "react-router-dom";


function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <button className="down-arrow" onClick={scrollToTop}>
        <img src={downArrow} alt="" />
      </button>
      <div className="footer">
        <div className="footer1">
          {/* <img src={logo} />
          <p>
            Lorem Ipsum is simply dummy text of the<br />
            printing and typesetting industry. Lorem<br />
            Ipsum has been the industry's standard <br />
            dummy text ever since the 1500s
          </p> */}
          <img src={social} />
        </div>
        <div className="fotul">
          <div className="footer2">
            <ul className="footul">
              MENU
              <Link className="link" to="/">
                <li>Home</li>
              </Link>
              <Link className="link" to="/shop">
                <li>Shop</li>
              </Link>
              <Link className="link" to="/shop">
                <li>Products</li>
              </Link>
              <Link className="link" to="/blog">
                <li>Blogs</li>
              </Link>
              <Link className="link" to="/about">
                <li>About</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="fotul">
          <div className="footer2">
            <ul className="footul">
              Customer Policies
              <Link className="link" to="/Privacy_policy">
                <li>Privacy Policy</li>
              </Link>
              <Link className="link" to="/Terms&Conditions">
                <li>Terms&Conditions</li>
              </Link>
              <Link className="link" to="/returenPolicy">
                <li>Exchange Policy</li>
              </Link>
              <Link className="link" to="/Shippingpolicy">
                <li>Shipping Policy</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="footer2">
          <ul>Newsletter</ul>
          <br />
          <p>
            Subscribe to the newsletter and get some
            <br /> crispy stuff every week.
          </p>
          <br />
          <div className="newsletter">
            <input />
            <button>Send</button>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="copyright">
        <p className="mt-3">
        Copyright © 2024 Zabelo. All Rights Reserved.
        </p>
      </div>
    </>
  );
}

export default Footer;
