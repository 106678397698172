import React, { useState } from "react";
import MainContext from "./MainContext";



const MainState = (props) => {
    const [wishlistCount, setWishlistCount]= useState(0)
    const [cartCount, setCartCount]=useState(0)
    const handleWishlistChange = (val)=>{
        setWishlistCount(val)
    }
    const handleCartChange = (val)=>{
        setCartCount(val)
    }
    return (
        <>
            <MainContext.Provider value={{
                handleWishlistChange,
                wishlistCount,
                handleCartChange,
                cartCount
            }}
            >
                {props.children}
            </MainContext.Provider>
        </>
    )
}

export default MainState