import React, { useEffect, useState } from "react";
import "./SlidingText.css"; // Create this CSS file for the styling

const SlidingText = ({ texts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000); // Change text every 3 seconds
    return () => clearInterval(interval);
  }, [texts]);

  return (
    <div className="sliding-text-container">
      <div className="sliding-text" key={texts[currentIndex]}>
        {texts[currentIndex].text}
      </div>
    </div>
  );
};

export default SlidingText;
