import React, { useEffect, useState } from "react";
import Dim from "../../Assets/Screenshot 2023-10-13 110103.png";
import axios from "axios";
import { SyncLoader } from "react-spinners";
import { GiShoppingCart } from "react-icons/gi";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../Url";
import "../Store.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useMain } from "../../Hooks/useMain";

function Wishlistproduct() {
  const navigate = useNavigate();

  const [Prodcuts, setProdcuts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { handleCartChange } = useMain();

  const Get_User_Wishlist = async () => {
    const token = localStorage.getItem("UserToken");
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/api/wishlist/getwishlist`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response?.data?.data?.products, 'aaaa');
      setProdcuts(response?.data?.data?.products);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    Get_User_Wishlist();
  }, []);

  // ===================[Add to Wishlist api]=======================//

  const WishlistTocart = async (ProdcutId) => {
    const token = localStorage.getItem("UserToken");
    try {
      const response = await axios.get(
        `${API_URL}/api/wishlist/moveproduct/${ProdcutId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Addtocartlist(ProdcutId);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // ===================[Add to cart api]=======================//

  const Addtocartlist = async (ProdcutId) => {
    const token = localStorage.getItem("UserToken");
    const type = "add";
    try {
      const response = await axios.get(
        `${API_URL}/user/cart/product/${ProdcutId}/${type}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        handleCartChange(response?.data?.data?.cart?.products?.length || 0);
        toast.success(response.data.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error fetching data:", error);
    }
  };

  const ProductClick = (item) => {
    navigate(`/ProductDetail/${encodeURIComponent(item?.displayName)}/${item._id}`, {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="featuredproducts" style={{ marginBottom: "70px" }}>
        <div className="text-center">
          <img className="dim" src={Dim} alt="Dim" />
          <h1>My WishList</h1>
        </div>
      </div>
      <div className="container my-5">
        <Row>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <span class="loader"></span>
            </div>
          ) : Prodcuts?.length > 0 ? (
            Prodcuts?.map((product) => (
              <Col
                key={product.id}
                className="mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div class="card" style={{ width: "16rem" }}>
                  <div class="card-body">
                    <div className="featuredproducts-card-imgnew">
                      <img
                        onClick={() => {
                          ProductClick(product?.product);
                        }}
                        src={product?.product?.displayImage[0]?.url}
                        alt={`Product ${product?.product?.id}`}
                        width={240}
                        height={300}
                        style={{ borderRadius: "20px" }}
                      />

                      <div
                        onClick={() => {
                          WishlistTocart(product?.product?._id);
                        }}
                        style={{
                          position: "absolute",
                          bottom: "85px",
                          right: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "35px",
                          height: "35px",
                          backgroundColor: "#ffff",
                          borderRadius: "50%",
                        }}
                      >
                        <GiShoppingCart />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="text-center">
                        Rs. {product?.product?.price?.toFixed(2)}
                      </h5>
                      <h6 className="text-center">
                        {product?.product?.displayName?.slice(0, 25)}
                      </h6>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="col-sm-12">
              <p className="text-center">No products in the wishlist.</p>
            </div>
          )}
        </Row>
      </div>
    </>
  );
}

export default Wishlistproduct;
