import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import fail from '../Assets/failed.png'

function PaymentFail() {
  return (
    <>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
       <div className='failed'>
       <img src={fail}/>
        <h1>Payment Failed! Please try again</h1>
        <button>Back To Home</button>

       </div>

        <Footer/>
    </>
  )
}

export default PaymentFail