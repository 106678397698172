import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Testmonials from "../Components/Testmonials";
import Footer from "../Components/Footer";
import FeaturedProducts from "./Home/FeaturedProducts";
import ShopByStyle from "./Home/ShopByStyle";
import Offer from "./Home/Offer";
import Trendingproduct from "./Home/Trendingproduct";
import Explore from "./Home/Explore";
import Shipping from "./Home/Shipping";
import ExclusiveOffer from "./Home/ExclusiveOffer";
import Cosmetics from "./Home/Cosmetics";
import HeroSlider from "./Home/Slider";

function Home() {

  if (!localStorage.getItem("cart")) {
    localStorage.setItem("cart", JSON.stringify([]));
  }
  if (!localStorage.getItem("Wishlist")) {
    localStorage.setItem("Wishlist", JSON.stringify([]));
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Navbar />
      <HeroSlider />
      <Explore />
      <ShopByStyle />
      <ExclusiveOffer />
      <FeaturedProducts />
      <Offer />
      <Trendingproduct />
      <Cosmetics />
      <Testmonials />
      <Shipping />
      <Footer />
    </>
  );
}

export default Home;
