import React, { useEffect, useState } from "react";
import Dim from "../../Assets/Screenshot 2023-10-13 110103.png";
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../Url";
import "../Store.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GiShoppingCart } from "react-icons/gi";

function NewWishlist() {
  const navigate = useNavigate();

  const [Prodcuts, setProdcuts] = useState([]);
  const [loading, setLoading] = useState(true);

  const Get_User_Wishlist = async () => {
    try {
      const Wishlistdatanew = JSON.parse(localStorage.getItem("Wishlist"));
      setProdcuts(Wishlistdatanew || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    Get_User_Wishlist();
  }, []);

  // ===================[Add to Wishlist api]=======================//

  const WishlistToCart = (product) => {
    try {
      // Get the existing wishlist from local storage
      const existingWishlist =
        JSON.parse(localStorage.getItem("Wishlist")) || [];

      // Find the index of the product in the wishlist
      const productIndex = existingWishlist.findIndex(
        (item) => item._id === product._id
      );

      if (productIndex !== -1) {
        // Remove the product from the wishlist
        const removedProduct = existingWishlist.splice(productIndex, 1)[0];

        // Get the existing cart from local storage
        const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

        // Add the removed product to the cart
        existingCart.push(removedProduct);

        // Update local storage for both cart and wishlist
        localStorage.setItem("Wishlist", JSON.stringify(existingWishlist));
        localStorage.setItem("cart", JSON.stringify(existingCart));

        // Optionally, you can perform any other actions after successful move
        console.log("Product moved from Wishlist to Cart successfully!");
        toast.success("Product moved from Wishlist to Cart successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        Get_User_Wishlist();
      } else {
        console.warn("Product not found in Wishlist.");
      }
    } catch (error) {
      console.error(
        "Error moving product from Wishlist to Cart:",
        error.message
      );
    }
  };

  const ProductClick = (item) => {
    navigate(`/ProductDetail/${encodeURIComponent(item?.displayName)}/${item._id}`, {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="featuredproducts" style={{ marginBottom: "70px" }}>
        <div className="text-center">
          <img className="dim" src={Dim} alt="Dim" />
          <h1>My WishList</h1>
        </div>
      </div>
      <div className="container w-100">
        <div className="row w-100">
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <span class="loader"></span>
            </div>
          ) : Prodcuts?.length > 0 ? (
            Prodcuts?.map((product) => (
              <div
                key={product.id}
                className="col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  class="product-card"
                  style={{ width: "100%" }}
                  key={product?.id}
                >
                  <div className="featuredproducts-card-imgnew">
                    <img
                      onClick={() => {
                        ProductClick(product);
                      }}
                      src={product?.displayImage[0]?.url}
                      alt={`Product ${product?.id}`}
                      style={{
                        height: "300px",
                        padding: "14px 10px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="product-card-content">
                    <h5 className="text-center">
                      Rs. {product?.price?.toFixed(2)}
                    </h5>
                    <h6 className="text-center">
                      {product?.displayName?.slice(0, 25)}
                    </h6>
                    <button
                      className="add-card-btn"
                      onClick={() => {
                        WishlistToCart(product);
                      }}
                    >
                      Add To Cart
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-sm-12">
              <p className="text-center">No products in the wishlist.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default NewWishlist;
