import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img1 from '../Assets/Mask group.png'


function TandP() {
  return (
    <>
      <Navbar />
      <br/>
      <br/>
      <br/>

      <div className='tnc'>


        <h1>Terms & Condition</h1>
        <br />
        <h3>Terms of use</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, quidem doloribus cumque vero, culpa voluptates dolorum reprehenderit nihil nisi odit necessitatibus voluptate voluptatibus magni ducimus sed accusamus illo nobis veniam.</p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, quidem doloribus cumque vero, culpa voluptates dolorum reprehenderit nihil nisi odit necessitatibus voluptate voluptatibus magni ducimus sed accusamus illo nobis veniam.</p>



        <br />


        <h3>Terms of use</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, quidem doloribus cumque vero, culpa voluptates dolorum reprehenderit nihil nisi odit necessitatibus voluptate voluptatibus magni ducimus sed accusamus illo nobis veniam.</p>
      </div>
      <div className='servics'>
        <div className='servicescard'>
          <img src={img1} />
          <div className='free'>
            <h4>Free Shipping</h4>
            <p>Free shipping all order</p>
          </div>

        </div>
        <div className='servicescard'>
          <img src={img1} />
          <div className='free'>
            <h4>Free Shipping</h4>
            <p>Free shipping all order</p>
          </div>

        </div>
        <div className='servicescard'>
          <img src={img1} />
          <div className='free'>
            <h4>Free Shipping</h4>
            <p>Free shipping all order</p>
          </div>

        </div>
        <div className='servicescard'>
          <img src={img1} />
          <div className='free'>
            <h4>Free Shipping</h4>
            <p>Free shipping all order</p>
          </div>

        </div>

      </div>

      <Footer />

    </>

  )
}

export default TandP