import React, { useEffect, useRef, useState } from "react";
import Dim from "../../Assets/Screenshot 2023-10-13 110103.png";
import axios from "axios";
import { SyncLoader } from "react-spinners";
import { GiShoppingCart } from "react-icons/gi";
import { FaRegHeart } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import { API_URL } from "../Url";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useMain } from "../../Hooks/useMain";
import leftArrow from "../../Assets/left-arrow.png";
import rightArrow from "../../Assets/right-arrow.png";
import Slider from "react-slick";

function FeaturedProducts() {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    infinite: true,
    slidesToShow: 5,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  const navigate = useNavigate();
  const { handleCartChange, handleWishlistChange } = useMain();

  const [Prodcuts, setProdcuts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const Get_All_products = async () => {
      try {
        const response = await axios.get(`${API_URL}/product/featured/get`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        setProdcuts(response.data.data.products);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    Get_All_products();
  }, []);

  //======================[ add to wishlist ] ===========================

  const AddtoWishlist = async (ProdcutId) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      toast.error("Please Login First", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const type = "add";
      try {
        const response = await axios.get(
          `${API_URL}/api/wishlist/product/${ProdcutId}/${type}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status == 200) {
          handleWishlistChange(
            response?.data?.data?.wishlist?.products?.length || 0
          );
          toast.success(response.data.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        // alert(error.response.data.error.message)
        toast.error(error.response.data.error.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error("Error fetching data:", error);
      }
    }
  };

  // ===================[Add to cart api]=======================//
  const Addtocartlist = async (ProdcutId) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      toast.error("Please Login First", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const type = "add";
      try {
        const response = await axios.post(
          `${API_URL}/user/cart/product/${ProdcutId?._id}/${type}`,
          {
            variant: ProdcutId?.priceVarient[0]?.varient,
            varientPrice: ProdcutId?.priceVarient[0]?.price,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status == 200) {
          handleCartChange(response?.data?.data?.cart?.products?.length || 0);
          toast.success(response.data.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        toast.error(error.response.data.error.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error("Error fetching data:", error);
      }
    }
  };

  const ProductClick = (item) => {
    navigate(`/ProductDetail/${encodeURIComponent(item?.displayName)}/${item._id}`, {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  };

  function addToCart(item) {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItemIndex = cart.findIndex(
      (cartItem) =>
        cartItem.variant === item?.priceVarient[0]?.varient &&
        cartItem.varientPrice === item?.priceVarient[0]?.price &&
        cartItem?.product?._id == item?._id
    );
    if (existingItemIndex !== -1) {
      cart[existingItemIndex].quantity++;
    } else {
      cart.push({
        product: item,
        quantity: 1,
        variant: item?.priceVarient[0]?.varient,
        varientPrice: item?.priceVarient[0]?.price,
      });
    }
    localStorage.setItem("cart", JSON.stringify(cart));

    // let cart = JSON.parse(localStorage.getItem("cart")) || [];
    // const isItemInCart = cart.some((cartItem) => cartItem?._id === item._id);

    // if (!isItemInCart) {
    //   // Check if item has quantity field, if not, set it to 1
    //   if (!item.quantity) {
    //     item.quantity = 1;
    //   }

    //   cart.push(item);
    //   localStorage.setItem("cart", JSON.stringify(cart));
    //   handleCartChange(cart.length || 0);
    //   toast.success("Product Added to Cart", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // } else {
    //   toast.error("Already Added in Cart", {
    //     position: "top-right",
    //     autoClose: 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // }
  }

  const Addtocartmain = (product) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      addToCart(product);
      toast.success("Product Added to Cart", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      Addtocartlist(product);
    }
  };

  function AddtoWishlists(item) {
    let Wishlist = JSON.parse(localStorage.getItem("Wishlist")) || [];
    const isItemInWishlist = Wishlist.some(
      (wishlistItem) => wishlistItem?._id === item._id
    );

    if (!isItemInWishlist) {
      Wishlist.push(item);
      localStorage.setItem("Wishlist", JSON.stringify(Wishlist));
      handleWishlistChange(Wishlist.length || 0);

      toast.success("Product Added to Wishlist", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("Already Added in Wishlist", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const AddtoWishlistmain = (product) => {
    const token = localStorage.getItem("UserToken");
    if (token === null) {
      AddtoWishlists(product);
      // toast.success("Product Added to wishlist", {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    } else {
      AddtoWishlist(product._id);
    }
  };

  return (
    <>
      <div className="featuredproducts" style={{ marginBottom: "70px" }}>
        {/* <div>
          <img className='dim' src={Dim} alt="Dim" />
        </div> */}
        <div className="feature-title">
          <h1>Featured Products</h1>
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <button
              className="button"
              onClick={previous}
              style={{ border: "none", background: "transparent" }}
            >
              <img src={leftArrow} alt="" />
            </button>
            <button
              className="button"
              onClick={next}
              style={{ border: "none", background: "transparent" }}
            >
              <img src={rightArrow} alt="" />
            </button>
          </div>
        </div>
        <div className="featuredproducts-cards">
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            {loading ? (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <span class="loader"></span>
              </div>
            ) : (
              Prodcuts?.map((product) => (
                <div>
                  <div class="product-card" key={product?.id}>
                    <div className="featuredproducts-card-imgnew">
                      <img
                        onClick={() => {
                          ProductClick(product);
                        }}
                        src={product?.displayImage[0]?.url}
                        alt={`Product ${product?.id}`}
                        style={{
                          height: "300px",
                          padding: "14px 10px",
                          width: "98%",
                          objectFit: "cover",
                        }}
                      />
                      {/* <div
                     
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "35px",
                        height: "35px",
                        backgroundColor: "#ffff",
                        borderRadius: "50%",
                      }}
                    >
                      <GiShoppingCart />
                    </div> */}
                      <div
                        className="wish-list"
                        onClick={() => {
                          AddtoWishlistmain(product);
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "35px",
                          height: "35px",
                          backgroundColor: "#ffff",
                          borderRadius: "50%",
                        }}
                      >
                        <FaRegHeart />
                      </div>
                    </div>
                    <div className="product-card-content">
                      <h6>{product?.displayName?.slice(0, 25)}</h6>
                      <h5>Rs. {product?.price?.toFixed(2)}</h5>
                      <button
                        className="add-card-btn"
                        onClick={() => {
                          Addtocartmain(product);
                        }}
                      >
                        Add To Cart
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </Slider>
        </div>
      </div>
    </>
  );
}
export default FeaturedProducts;
