import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import logo from '../Assets/image 68.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from './Url';

function Signup() {
  const [displayName, setdisplayName] = useState('');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');

  const navigate = useNavigate();

  const handlesignupSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${API_URL}/user/signup`,
        {
          displayName: displayName,
          email: email,
          password: password,
          phoneNumber: phoneNumber
        }
      );
      // alert(response.data.data.message);
      toast.success(response.data.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      localStorage.setItem("UserID", response.data.data.user._id);
      localStorage.setItem("UserToken", response.data.data.user.token);
      console.log(response.data)
      navigate("/");
      setemail("");
      setdisplayName("");
      setpassword("");
      setphoneNumber("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error.response.data.error.message);
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // alert(error.response.data.error.message)
    }
  };

  return (
    <>
      <Navbar />
      <div className='Login'>
        <div className='login-left' data-aos="fade-right">
          <div className='log-img'>
            <img src={logo} />
          </div>

          <p className='lof'>Create a new account</p>
          <div className='loginp'>
            <label>Username</label>
            <input
              placeholder='Enter Username'
              value={displayName}
              onChange={(e) => setdisplayName(e.target.value)}
            />
            <label>Email</label>
            <input
              type='email'
              placeholder='Enter Email'
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
            <label>Password</label>
            <input
              type='password'
              placeholder='Enter Password'
              value={password}
              onChange={(e) => setpassword(e.target.value)}
            />
            <label>Mobile No.</label>
            <input
              type='number'
              placeholder='Enter Mobile Number'
              value={phoneNumber}
              onChange={(e) => setphoneNumber(e.target.value)}
            />
          </div>

          <button onClick={handlesignupSubmit}>Sign up</button>
          <div className='fog'>
            <br />
            <p>Already have an account</p>
          </div>
          <button><Link to='/login' className='crtacc'>Login</Link></button>
        </div>
        <div className='login-right' data-aos="fade-left">
          <h1>We are more than just a company</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
