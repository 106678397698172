import React from 'react'
import Navbar from '../../Components/Navbar'
import Shipping from '../Home/Shipping'
import Footer from '../../Components/Footer'
import NewWishlist from './NewWishlist'

function Newwishlistproduct() {
  return (
    <>
      <Navbar />
      <NewWishlist/>
      <Shipping />
      <Footer />
    </>
  )
}

export default Newwishlistproduct