import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Contactus() {
  return (
   <>
    <Navbar/>
    <br/>
    <br/>
    <br/>
 
 <>
    <div className='contactus'>
        <div className='contact-left'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4307501.835969536!2d76.90324048418076!3d21.785583159447384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1697684458954!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


        </div>
        <div className='contact-right'>
        <h1>Send us a message</h1>
        <p>If you have any work from me or any types of quries related to my tutorial, you can send me message from here. It's my pleasure to help you</p>
        <form action="#">
        <div class="input-box">
          <input type="text" placeholder="Enter your name"/>
        </div>
        <div class="input-box">
          <input type="text" placeholder="Enter your email"/>
        </div>
        <div class="input-box">
          <input type="text" placeholder="Enter your email"/>
        </div>
        <div class="input-box">
          <textarea/>
        </div>
        <div class="input-box message-box">
          
        </div>
        <div class="button">
          <input type="button" value="Send Now" />
        </div>
      </form>
</div>
    </div>
 </>



    <Footer/>
   </>
  )
}

export default Contactus