import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { IoIosArrowForward } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { API_URL } from "../Url";

function NewCart() {
  const [Cartdata, setCartdata] = useState([]);
  const [Addresses, setAddresses] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [Number, setNumber] = useState("");
  const [Address, setAddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  const [method, setMethod] = useState();
  const [userMail, setUserMail] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [Coupon_code, setCoupon_code] = useState("");

  const Get_User_Cart = async () => {
    const Cartdatanew = JSON.parse(localStorage.getItem("cart"));
    setCartdata(Cartdatanew || []);
    const NewAddress = JSON.parse(localStorage.getItem("addresses"));
    setAddresses(NewAddress || []);
    const usermail2 = localStorage.getItem("useremail");
    setUserMail(usermail2);
  };
  console.log(Cartdata);

  useEffect(() => {
    Get_User_Cart();
  }, []);

  // console.log(Addresses)
  useEffect(() => {
    if (Cartdata?.length > 0) {
      const calculatedTotal = Cartdata?.reduce((total, cartItem) => {
        return total + cartItem?.varientPrice * cartItem?.quantity;
      }, 0);
      setTotalAmount(calculatedTotal);
      if (appliedCoupon) {
        if (calculatedTotal < appliedCoupon.min_price) {
          setAppliedCoupon(null);
          setDiscountedAmount(0);
          toast.info("Coupon removed due to item removal.", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          const discountAmount =
            (calculatedTotal * appliedCoupon.discount_percent) / 100;
          setDiscountedAmount(discountAmount);
        }
      }
    }
  }, [Cartdata, appliedCoupon]);

  const Get_coupon_datails = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${API_URL}/coupon/${Coupon_code}/get`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const couponData = response?.data?.data?.coupon;

      // Check if the coupon can be applied
      if (couponData.is_active && totalAmount >= couponData.min_price) {
        const discountAmount =
          (totalAmount * couponData.discount_percent) / 100;
        setAppliedCoupon(couponData);
        setDiscountedAmount(discountAmount);
        toast.success("Coupon Applied", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(
          `Selected Item should be more than ${couponData.min_price} for the Coupon`,
          {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    } catch (error) {
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error fetching cart data:", error);
    }
  };

  const DeleteFromcartlist = async (ProductId, cartItem) => {
    try {
      console.log(cartItem);
      const cartData = JSON.parse(localStorage.getItem("cart")) || [];

      const indexToRemove = cartData.findIndex(
        (item) =>
          item.variant === cartItem.variant &&
          item.varientPrice === cartItem.varientPrice &&
          item?.product?._id == cartItem?.product?._id
      );

      if (indexToRemove !== -1) {
        cartData.splice(indexToRemove, 1);

        localStorage.setItem("cart", JSON.stringify(cartData));

        setCartdata([...cartData]);

        toast.success("Item removed from cart", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.log("Item not found in the cart");
      }
    } catch (error) {
      console.error("Error removing item from cart:", error.message);
    }
  };

  const UpdateCartQuantity = async (ProductId, action, cart) => {
    try {
      console.log(ProductId, action, cart);
      const cartData = JSON.parse(localStorage.getItem("cart")) || [];
      const updatedCartData = cartData
        .map((item) => {
          if (
            item.variant === cart.variant &&
            item.varientPrice == cart.varientPrice &&
            item?.product?._id == ProductId
          ) {
            if (action === "increase") {
              item.quantity += 1;
            } else {
              // Decrease quantity and remove if it becomes 0
              item.quantity -= 1;
              if (item.quantity === 0) {
                // Remove item from cart
                return null;
              }
            }
          }
          return item;
        })
        .filter(Boolean); // Filter out null items

      localStorage.setItem("cart", JSON.stringify(updatedCartData));
      setCartdata([...updatedCartData]);

      // toast.success(`Cart quantity ${action === "increase" ? "increased" : "decreased"} successfully`, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    } catch (error) {
      console.error("Error updating cart quantity:", error.message);
    }
  };

  const AddressFormsubmit = () => {
    const newId = uuidv4();
    setUserMail(email);
    const newAddress = {
      _id: newId,
      name,
      email,
      number: Number,
      address: Address,
      state,
      city,
      pincode,
    };
    try {
      const existingAddresses =
        JSON.parse(localStorage.getItem("addresses")) || [];
      existingAddresses.push(newAddress);
      localStorage.setItem("addresses", JSON.stringify(existingAddresses));
      localStorage.setItem("useremail", email);
      setname("");
      setemail("");
      setNumber("");
      setAddress("");
      setstate("");
      setcity("");
      setpincode("");
      console.log("Address added successfully!");
      toast.success("Address added successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      Get_User_Cart();
    } catch (error) {
      console.error("Error adding address:", error.message);
    }
  };

  const DeleteAddress = async (ProductId) => {
    try {
      const cartData = JSON.parse(localStorage.getItem("addresses")) || [];

      const indexToRemove = cartData.findIndex(
        (item) => item._id === ProductId
      );

      if (indexToRemove !== -1) {
        cartData.splice(indexToRemove, 1);

        localStorage.setItem("addresses", JSON.stringify(cartData));

        setCartdata([...cartData]);

        toast.success("Address Removed !", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        Get_User_Cart();
      } else {
        console.log("Item not found in the address");
      }
    } catch (error) {
      console.error("Error removing item from Address:", error.message);
    }
  };

  const PlaceOrder = async () => {
    if (Addresses?.length === 0) {
      toast.success("Please Add Address First", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (method === undefined) {
      toast.success("Please Select payment Method", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (method === "cod") {
      try {
        const products = Cartdata.map((cartItem) => ({
          product: cartItem.product._id,
          quantity: cartItem.quantity,
          variant: cartItem.variant,
          varientPrice: cartItem.varientPrice,
        }));

        const orderData = {
          products,
          order_price: (totalAmount - (discountedAmount ?? 0)).toFixed(2),
          coupon_applied: null,
          shippingAddress: Addresses?.map((address) => ({
            address: address.address,
            pincode: address.pincode,
          })),
          shippingAddress1: Addresses[0] || {},
          email: userMail,
          payment_mode: "COD",
        };

        const response = await axios.post(
          `${API_URL}/user/order/place1`,
          orderData
        );

        if (response.status === 200) {
          toast.success("Order placed successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setCartdata([]);
          setAddresses([]);
          localStorage.setItem("cart", JSON.stringify([]));
          localStorage.setItem("addresses", JSON.stringify([]));
        }
      } catch (error) {
        console.error("Error placing order:", error);
      }
    } else if (method === "online") {
      try {
        const products = Cartdata.map((cartItem) => ({
          product: cartItem.product._id,
          quantity: cartItem.quantity,
          variant: cartItem.variant,
          varientPrice: cartItem.varientPrice,
        }));

        const orderData = {
          products,
          order_price: (totalAmount - (discountedAmount ?? 0)).toFixed(2),
          coupon_applied: null,
          shippingAddress: Addresses?.map((address) => ({
            address: address.address,
            pincode: address.pincode,
          })),
          shippingAddress1: Addresses[0] || {},
          email: userMail,
          payment_mode: "ONLINE",
        };

        const response = await axios.post(
          `${API_URL}/user/order/place1`,
          orderData
        );
        if (response.status === 200) {
          try {
            const res = await axios.post(
              `https://zebelo-backend.vercel.app/phonepe/payment`,
              {
                name: Addresses[0]?.name,
                amount: (totalAmount - (discountedAmount ?? 0))
                  .toFixed(2)
                  .toString(),
                number: Addresses[0]?.number,
                merchantUserId: response?.data?.data?.order?._id,
              }
            );
            console.log(res, "bbbbb");
            window.location.href = res.data;
            setCartdata([]);
            setAddresses([]);
            localStorage.setItem("cart", JSON.stringify([]));
            localStorage.setItem("addresses", JSON.stringify([]));
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.error("Error placing order:", error);
      }
    }
  };
  // console.log(
    // Cartdata.map((e, i) => {
    //   console.log(e);
    // })
  // );
  return (
    <>
      <Navbar />
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="cart">
        <div className="cartnav">
          <h3 className="m-0">
            Home <IoIosArrowForward /> Shop <IoIosArrowForward /> Add To Cart
          </h3>
        </div>
        <>
          <>
            <div className="table-responsive">
              <table className="table tablefont">
                <thead
                  className="table-dark tablehi"
                  style={{ verticalAlign: "middle", textAlign: "center" }}
                >
                  <tr>
                    <th scope="col">Product Detail</th>
                    <th scope="col">Variant</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Shipping</th>
                    {/* <th scope="col">SubTotal</th> */}
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    height: "160px",
                    verticalAlign: "middle",
                    textAlign: "center",
                  }}
                >
                  {Cartdata?.length > 0 ? (
                    Cartdata?.map((cartItem) => (
                      <tr key={cartItem?._id} style={{ height: "120px" }}>
                        <td style={{ width: "350px", marginLeft: "30px" }}>
                          <img
                            src={cartItem?.product?.displayImage[0]?.url}
                            alt={cartItem?.product?.displayName}
                            style={{
                              width: "50px",
                              height: "50px",
                              marginRight: "10px",
                              borderRadius: "10px",
                            }}
                          />
                          {cartItem?.product?.displayName?.slice(0, 10)}
                        </td>
                        <td>{cartItem?.variant}</td>
                        <td>Rs. {cartItem?.varientPrice}</td>
                        {/* <td>{cartItem?.quantity}</td> */}
                        <td>
                          {" "}
                          <button
                            onClick={() =>
                              UpdateCartQuantity(
                                cartItem?.product?._id,
                                "decreased",
                                cartItem
                              )
                            }
                            style={{
                              padding: "0px 15px",
                              marginRight: "5px",
                              borderRadius: "10px",
                              border: "none",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            -
                          </button>
                          {cartItem.quantity}
                          <button
                            onClick={() =>
                              UpdateCartQuantity(
                                cartItem?.product?._id,
                                "increase",
                                cartItem
                              )
                            }
                            style={{
                              padding: "0px 15px",
                              marginLeft: "5px",
                              border: "none",
                              borderRadius: "10px",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            +
                          </button>
                        </td>
                        <td>Free</td>
                        {/* <td>Rs. {(cartItem?.price * 1).toFixed(2)}</td> */}
                        <td
                          onClick={() =>
                            DeleteFromcartlist(cartItem?._id, cartItem)
                          }
                        >
                          <MdDelete style={{ fontSize: "24px" }} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No items in the cart</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="address-container">
              <div className="row">
                <div className="col-sm-7 mt-5" >
                  <h4 className="add-title">Add Address</h4>
                  <Row>
                    <Col>
                      <Form.Group controlId="formName">
                        <Form.Label className="label-title">Name</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          placeholder="Enter Name"
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="formName">
                        <Form.Label className="label-title">Email</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="formName" className="mt-1">
                    <Form.Label className="label-title">
                      Contact Number
                    </Form.Label>
                    <Form.Control
                      className="form-input"
                      type="number"
                      placeholder="Enter Contact Number"
                      value={Number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formName" className="mt-1">
                    <Form.Label className="label-title">Address</Form.Label>
                    <Form.Control
                      className="form-input"
                      type="text"
                      placeholder="Enter Address"
                      value={Address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Form.Group>
                  <Row className="mt-1">
                    <Col>
                      <Form.Group controlId="formName">
                        <Form.Label className="label-title">State</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          placeholder="Enter State"
                          value={state}
                          onChange={(e) => setstate(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="formName">
                        <Form.Label className="label-title">City</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          placeholder="Enter City"
                          value={city}
                          onChange={(e) => setcity(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="formName" className="mt-1">
                    <Form.Label className="label-title">Pin code</Form.Label>
                    <Form.Control
                      className="form-input"
                      type="number"
                      placeholder="Enter Pin code"
                      value={pincode}
                      onChange={(e) => setpincode(e.target.value)}
                    />
                  </Form.Group>
                  <button
                    onClick={AddressFormsubmit}
                    className="Add-address-btn mt-3"
                  >
                    Add Address
                  </button>
                  <br />
                  <br />
                  <form>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-input"
                        placeholder="Enter Coupon"
                        value={Coupon_code}
                        onChange={(e) => setCoupon_code(e.target.value)}
                      />
                    </div>
                    <div className="mt-3">
                      <button
                        onClick={(e) => {
                          Get_coupon_datails(e);
                        }}
                        className="Add-address-btn mt-3"
                      >
                        Apply Coupon
                      </button>
                    </div>
                  </form>
                
                </div>
                <div
                  className="col-sm-4 totalpad "
                  style={{
                    marginTop: "5rem",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  {Addresses?.length > 0 ? (
                    Addresses?.map((Address) => (
                      <div className="card mb-2" style={{ width: "100%" }}>
                        <div className="card-body">
                          <h5 className="card-title">Address</h5>
                          <Row>
                            <Col>
                              <h6 className="card-subtitle mb-2 text-muted">
                                {Address?.name}
                              </h6>
                            </Col>
                            <Col>
                              <p className="card-subtitle mb-2 text-muted">
                                {Address?.email}
                              </p>
                            </Col>
                          </Row>
                          <p className="card-text">{Address?.address}</p>
                          <Row>
                            <Col>
                              <p className="card-text">{Address?.city} </p>
                            </Col>
                            <Col>
                              <p className="card-text">{Address?.state} </p>
                            </Col>
                          </Row>
                          <h6 className="card-subtitle mb-2 text-muted mt-2">
                            {" "}
                            PinCode : {Address?.pincode}
                          </h6>
                          <button
                            onClick={() => {
                              DeleteAddress(Address?._id);
                            }}
                            style={{ backgroundColor: "#4e2985" }}
                            className="Add-address-btn mt-3"
                          >
                              Remove Address
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <h2 className="add-title">No Address added yet</h2>
                    </div>
                  )}
                  <div className="profile12" style={{ marginTop: "20px" }}>
                    <h2 className="font-semibold text-[12px] md:text-[12px] l:text-[15px] xl:text-[18px] mt-2 mb-1">
                      Payment Method
                    </h2>
                    <div style={{ marginTop: "20px" }}>
                      <button
                        style={{
                          padding: "1rem 0.5rem",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                          borderRadius: "10px",
                          border: "2px solid #001963",
                          backgroundColor: "white",
                        }}
                        onClick={async (e) => {
                          setMethod("online");
                        }}
                      >
                        <input
                          type="radio"
                          value="online"
                          checked={method == "online"}
                        />
                        &nbsp; Online
                      </button>{" "}
                      &nbsp;&nbsp;
                      <button
                        style={{
                          padding: "1rem 0.5rem",
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                          borderRadius: "10px",
                          border: "2px solid #001963",
                          backgroundColor: "white",
                        }}
                        onClick={async (e) => {
                          setMethod("cod");
                        }}
                      >
                        <input
                          type="radio"
                          value="cod"
                          checked={method == "cod"}
                        />
                        &nbsp; Cash On Delivery
                      </button>
                    </div>
                  </div>
                  <p className="itemselected" style={{ fontFamily: "poppins" }}>
                    Total items selected (
                    {Cartdata && Cartdata?.length ? Cartdata?.length : 0} items)
                  </p>
                  <div className="totalMRP">
                    <p style={{fontFamily: 'poppins'}}>Total MRP</p>
                    <p>
                      <strong style={{fontFamily: 'poppins'}}>Rs. {totalAmount.toFixed(2)}</strong>
                    </p>
                  </div>
                  {/* <div className="discount">
                  <p>Discount</p>
                  <p style={{ color: '#C02026' }}>-00</p>
                </div> */}
                  {appliedCoupon ? (
                    <>
                      <div className="discount" style={{fontFamily: 'Poppins'}}>
                        <p >{`Discount (${appliedCoupon.code})`}</p>
                        <p 
                          style={{ color: "#C02026" }}
                        >{`-${discountedAmount.toFixed(2)}`}</p>
                      </div>
                      <div className="totalMRP">
                        <p >Convenience fees</p>
                        <p>0.00</p>
                      </div>
                      <div className="billline"></div>
                      <br />
                      <div className="totalMRP">
                        <p>
                          <strong style={{fontFamily: 'Poppins'}}>Total Amount</strong>
                        </p>
                        <p>
                          <strong style={{fontFamily: 'Poppins'}}>
                            Rs. {(totalAmount - discountedAmount).toFixed(2)}
                          </strong>
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="discount">
                        <p>Discount</p>
                        <p style={{ color: "#C02026" }}>-00</p>
                      </div>
                      <div className="totalMRP">
                        <p>Convenience fees</p>
                        <p>0.00</p>
                      </div>
                      <div className="billline"></div>
                      <br />
                      <div className="totalMRP">
                        <p>
                          <strong>Total Amount</strong>
                        </p>
                        <p>
                          <strong>Rs. {totalAmount.toFixed(2)}</strong>
                        </p>
                      </div>
                    </>
                  )}
                  {/* <div className="totalMRP">
                  <p>Convenience fees</p>
                  <p>000</p>
                </div>
                <div className="billline"></div>
                <br />
                <div className="totalMRP">
                  <p>
                    <strong>Total Amount</strong>
                  </p>
                  <p>
                    <strong>Rs. {totalAmount.toFixed(2)}</strong>
                  </p>
                </div> */}
                  <button
                    onClick={() => {
                      PlaceOrder();
                    }}
                    className="Add-address-btn"
                    style={{ width: "100%" }}
                  >
                    Place Order
                  </button>
                </div>
              </div>
            </div>
          </>
        </>
      </div>
      <hr />
      <Footer />
    </>
  );
}

export default NewCart;
