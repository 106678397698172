import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import img from "../Assets/image 58.png";
function CheckOut() {
  return (
    <>
      <Navbar />
      <div className="cartnav">
        <h3>Home > Shop > Brick Red Bag > Add To Cart | Checkout</h3>
      </div>
      <div className="checkiut">
        <h1>Check Out</h1>
        <br />
        <h2>Billing Details</h2>

        <div className="details-div">
          <div className="form-section">
            <div>
              <div className="Form-inputs">
                <div className="inputss">
                  <label>First Name</label>
                  <input placeholder="First Name" />
                </div>
                <div className="inputss">
                  <label>Last Name</label>
                  <input placeholder="Last Name" />
                </div>
              </div>
              <br />
              <div className="Form-inputs">
                <div className="inputss">
                  <label>Country / Region*</label>
                  <input placeholder="Country / Region" />
                </div>
                <div className="inputss">
                  <label>Company Name</label>
                  <input placeholder="Company Name" />
                </div>
              </div>
              <br />
              <div className="Form-inputs">
                <div className="inputss">
                  <label>Street Address*</label>
                  <input placeholder="House number and street name" />
                </div>
                <div className="inputss">
                  <label>Apt, suite, unit</label>
                  <input placeholder="apartment, suite, unit, etc. (optional)" />
                </div>
              </div>
              <br />
              <div className="Form-inputs">
                <div className="inputss inp">
                  <label>City*</label>
                  <input placeholder="City" />
                </div>
                <div className="inputss inp">
                  <label>State*</label>
                  <input placeholder="State" />
                </div>
                <div className="inputss inp">
                  <label>Postal Code*</label>
                  <input placeholder="Postal Code" />
                </div>
              </div>
              <br />
              <div className="inputss">
                <label>Phone*</label>
                <input placeholder="Phone" />
              </div>
            </div>
            <br />
            <button className="Continuetodelivery">Continue to delivery</button>
            <br />
            <br />
            <div className="check-div">
              <input type="checkbox" className="checkbox" />
              <p>Save my information for a faster checkout</p>
            </div>

            <div className="bottom-div">
              <h1>Shipping Address</h1>
              <br />
              <p>Select the address that matches your card or payment method</p>
              <br />
              <div className="radio">
                <div className="radio-div">
                  <input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="HTML"
                  />
                    <label for="html">Same as Billing address</label>
                  <br />
                </div>
                <hr /> 
                <div className="radio-div">
                  <input
                    type="radio"
                    id="css"
                    name="fav_language"
                    value="CSS"
                  />
                    <label for="css">Use a different shipping address</label>
                  <br />
                </div>
              </div>
              <br />
              <button>Pay Now</button>
            </div>
          </div>
          <div className="ordersummary">
            <div className="sume">
              <h1> Order Summary</h1>
              <hr />
              <div className="order-row">
                <div className="d-flex align-items-center gap-3">
                  <img src={img} />
                  <div className="ord-row-text">
                    <h3>Brick Red Bag</h3>
                    <p> Color : Yellow</p>
                  </div>
                </div>
                <p>$29.00</p>
              </div>
              <hr />
              <div className="order-row">
                <div className="d-flex align-items-center gap-3">
                  <img src={img} />
                  <div className="ord-row-text">
                    <h3>Brick Red Bag</h3>
                    <p> Color : Yellow</p>
                  </div>
                </div>
                <p>$29.00</p>
              </div>
              <hr />
              <div className="order-row">
                <div className="d-flex align-items-center gap-3">
                  <img src={img} />
                  <div className="ord-row-text">
                    <h3>Brick Red Bag</h3>
                    <p> Color : Yellow</p>
                  </div>
                </div>
                <p>$29.00</p>
              </div>
              <hr />

              <div className="order-row">
                <div className="ord-row-text">
                  <p> Subtotal ( 3 items )</p>
                </div>
                <p>Rs. 513.00</p>
              </div>
              <div className="order-row">
                <div className="ord-row-text">
                  <p> Savings</p>
                </div>
                <p>-Rs. 30.00</p>
              </div>
              <hr />
              <div className="order-row">
                <div className="ord-row-text">
                  <p> Shipping</p>
                </div>
                <p>-Rs. 5.00</p>
              </div>
              <hr />
              <div className="order-row">
                <div className="ord-row-text">
                  <p>Total</p>
                </div>
                <p>Rs. 478.00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      |<Footer />
    </>
  );
}

export default CheckOut;
