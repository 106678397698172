import React,{useEffect} from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Shipping from './Home/Shipping'

function Privacypolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <br/>
      <br/>
      <br/>
      
      <>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12">
              <h2 className='text-center mt-5 mb-1' style={{ fontWeight: '700' }}>Privacy policy</h2>
              <p>This Privacy Policy governs the manner in which ZABELO collects, uses, maintains and discloses information collected from users (each, a "User") of the www.shopzabelo.com website ("Site"). This Privacy Policy applies to this website, official social media pages / handles / channels and all products offered by ZABELO</p>
              <h4 className='mt-4 mb-4'>Personal Identification Information</h4>
              <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, submits style preferences, submit feedback forms, write reviews and testimonials, place an order, subscribe to the newsletter, direct login using personal social media credentials, respond to a survey, and in connection with other activities, products, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except when it may prevent them from engaging in certain Site related offerings.</p>
              <h4 className='mt-4 mb-4'>Non-personal-Identification information</h4>
              <p>We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.This information will not identify you personally and will not be linked back to you.</p>
              <h4 className='mt-4 mb-4'>How we use collected information</h4>
              <p>ZABELO may collect and use Users personal information for the following purposes: </p>
              <li className='mt-3'>•	To improve customer service
              </li>
              <li>
                Information you provide helps us respond to your customer service requests and support needs more efficiently.
              </li>
              <li className='mt-3'>
                •	To personalize user experience
              </li>
              <li>
                We may use information in the aggregate to understand how our Users as a group use the resources provided on our Site.
              </li>
              <li className='mt-3'>
                •	To improve our Site
              </li>
              <li>
                We may use feedback you provide to improve our products and offerings
              </li>
              <li className='mt-3'>
                •	To process payments
              </li>
              <li>
                We may use the information Users provide about themselves when placing an order only to fulfill that order. We do not share this information with any outside parties except to the extent necessary to execute the order.
              </li>
              <li className='mt-3'>
                •	To run a promotion, contest, survey or other Site feature
              </li>
              <li>
                To send Users information they agreed to receive about topics we think will be of interest to them.
              </li>
              <li className='mt-3'>
                •	To send periodic emails
              </li>
              <li>
                We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.
              </li>
              <h6 className='mt-4 mb-4' style={{fontWeight:'600'}}>Web browser cookies</h6>
              <p>Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
              <h6 className='mt-4 mb-4' style={{fontWeight:'600'}}>How we protect your information</h6>
              <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.
                Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected.
              </p>
              <h4 className='mt-4 mb-4'>Sharing your personal information</h4>
              <p>We do not sell, trade, or rent Users personal identification information to any other individual or parties. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</p>
              <h5 className='mb-4 mt-4'>Information available</h5>
              <p>Information available on this website may contain technically inaccurate and topographical errors. Information on this website may be changed or updated without any prior notice. We may also make improvements to the offerings described in this website and may change without prior notice.</p>
              <h5 className='mb-4 mt-4'>Newsletters</h5>
              <p>If you wish to subscribe to our newsletter(s), we will use your email id and email address to send the newsletters to you. To respect your privacy, we provide you an option to unsubscribe.</p>
              <h5 className='mb-4 mt-4'>Third party websites</h5>
              <p>Users may find advertising or other contents on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the contents or links that appear on these sites and we are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their contents and links, may be Your constantly changing. These sites and services may have their own Privacy Policies and Customer Service Policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own Terms and Conditions and Policies.
              </p>
              <h4 className='mb-4 mt-4'>Acceptance of these terms</h4>
              <p>By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
              <h4 className='mb-4 mt-4'>Changes to this Privacy Policy</h4>
              <p>ZABELO has the discretion to update this Privacy Policy at any time. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications, if any.</p>
              <h4 className='mb-4 mt-4'>Contacting Us</h4>
              <p>If you have any questions about this Privacy Policy, the practices of this site or your dealings with this site please contact us as per details on ‘Contact Us’ page.</p>
            </div>
          </div>
        </div>
      </>
      <Shipping />
      <Footer />
    </>
  )
}

export default Privacypolicy