import React, { useState, useEffect } from "react";
import p2 from "../../Assets/image 76.png";
import { SyncLoader } from "react-spinners";
import { API_URL } from "../Url";
import axios from "axios";
import "./Slider.css";
import Slider from "react-slick";

function HeroSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 9,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  };
  const [banners, setBanners] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [Category, setCategory] = useState([]);
  console.log(banners);
  const Get_All_banner = async () => {
    try {
      const response = await axios.get(`${API_URL}/banner/all`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log(response.data.data.banners, "sss");
      setBanners(response?.data?.data?.banners);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const Get_All_categerys = async () => {
    try {
      const response = await axios.get(`${API_URL}/product/category/all`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setCategory(response.data.data.categories);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    Get_All_banner();
    Get_All_categerys();
  }, []);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
  //   }, 3000);

  //   return () => clearInterval(timer);
  // }, [banners]);

  return (
    <div>
      <div className="adc">
        <Slider {...settings}>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <span class="loader"></span>
            </div>
          ) : (
            Category.map((category, index) => (
              <div>
                <div key={index} className="circle" data-aos="fade-up">
                  <img
                    src={category?.displayImage?.url}
                    height={60}
                    width={60}
                    alt={`Category ${index + 1}`}
                  />
                  <p className="text-center">{category?.name}</p>
                </div>
              </div>
            ))
          )}
        </Slider>
        {/* <div className="shortcuts"></div> */}
      </div>
      <div className="mt-0">
        <div
          id="carouselExampleSlidesOnly"
          className="carousel slide"
          data-ride="carousel"
        >
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <span class="loader"></span>
            </div>
          ) : (
            banners?.length > 0 && (
              <div className="slider">
                {banners?.length > 0 && (
                  <a
                    href={banners[currentIndex]?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={banners[currentIndex]?.bannerImage?.url}
                      alt="BannerImage"
                      className="slider-image"
                    />
                  </a>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default HeroSlider;
