import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Dim from "../Assets/Screenshot 2023-10-13 110103.png";
import { SyncLoader } from "react-spinners";
import Shipping from "./Home/Shipping";
import { API_URL } from "./Url";
import { useNavigate } from "react-router";

function Blog() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(blogs);
  useEffect(() => {
    // fetch(
    //   "https://bharat-360-app-vzet3.ondigitalocean.app/api/blog/getallblogs"
    // )
      fetch(`${API_URL}/blog/all`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setBlogs(data?.data?.blogs);
        // setBlogs(data.data.blogs);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
      setLoading(false);
  }, []);

  const BlogdetailClick = (item) => {
    navigate(`/blogdetail/${item._id}`, {
      state: {
        item: item,
      },
    });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />

      <div className="featuredproducts" style={{ marginBottom: "70px" }}>
        <div className="text-center">
          <img className="dim" src={Dim} alt="Dim" />
          <h1>All Blogs</h1>
        </div>
      </div>
      <div className="blogs">
        {/* {loading ? (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <span class="loader"></span>
          </div>
        ) : ( */}
          <div className="container">
            {blogs?.map((blog) => (
              
              <div className="card" key={blog._id} >
                <div
                  onClick={() => {
                    BlogdetailClick(blog);
                  }}
                >
                  <div className="card__header">
                    <img
                      src={blog?.displayImage[0]?.url}
                      alt="card__image"
                      className="card__image"
                      width="600"
                    />
                  </div>
                  <div className="card__body">
                    <h4>{blog?.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{ __html: blog?.content }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        {/* )} */}
      </div>
      <Shipping />
      <Footer />
    </>
  );
}

export default Blog;
