import React from "react";
import profile from "../Assets/Ellipse 105.png";
import profile2 from "../Assets/Ellipse 106.png";
import profile3 from "../Assets/Ellipse 107.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/bundle";
import Slider from "react-slick";

function Testmonials() {
  const testimonials = [
    {
      id: 1,
      text: "Thank you very much. I am very happy to be able to work here. The place is really comfortable and very clean, high level. I like it.",
      stars: 5,
      author: "David K.",
      role: "Customer",
      img: profile,
    },
    {
      id: 2,
      text: "Thank you very much. I am very happy to be able to work here. The place is really comfortable and very clean, high level. I like it.",
      stars: 5,
      author: "David K.",
      role: "Customer",
      img: profile3,
    },
    {
      id: 3,
      text: "Thank you very much. I am very happy to be able to work here. The place is really comfortable and very clean, high level. I like it.",
      stars: 5,
      author: "David K.",
      role: "Customer",
      img: profile2,
    },
  ];
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="test-section">
        <div className="text-center">
          <h1 className="test-title">Testimonials</h1>
          <p className="test-text">What they say</p>
        </div>
        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div>
              <div className="testimonial" key={testimonial.id}>
                <div>
                  <img src={testimonial?.img} alt="Profile" />
                  {/* <img src={profile3} alt="Profile" /> */}
                  {/* <img src={profile2} alt="Profile" /> */}
                </div>
                <h2 className="test-sub-text" style={{maxWidth: "633px", margin: 'auto'}}>{testimonial.text}</h2>
                <div className="star">
                  {Array.from({ length: testimonial.stars }, (_, index) => (
                    <i key={index} className="bi bi-star-fill"></i>
                  ))}
                </div>
                <h3>{testimonial.author}</h3>
                <p >{testimonial.role}</p>
              </div>
            </div>
          ))}
        </Slider>
        {/* <Swiper
          spaceBetween={10}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
        >
        
        </Swiper> */}
      </div>
    </>
  );
}

export default Testmonials;
