import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Shipping from './Home/Shipping';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
function BlogDetails() {

  let location = useLocation();
  const productdata = location?.state?.item;
  return (
    <>
      <Navbar />
      {/* <div className="blogdetails">
        <div className='blog-left'>
          <img src={productdata.image} data-aos="zoom-in" alt="card__image" class="card__image" width="600" />
          <div className='blog-p' dangerouslySetInnerHTML={{ __html: productdata.content }}></div>
        </div>
        <div className='blog-right' data-aos="fade-left">
          <h1>{productdata.title}</h1>
          <div className='line'></div>
          <p className='blog-pp'>
            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.
          </p>
          <button><Link className='link' to='/blog'>More Blogs</Link></button>
        </div>
      </div> */}
      <div className="container">
        <div>
          <div className="d-flex flex-column align-items-center mt-5">
            <div className="mb-3">
              <img src={productdata?.image} className='blogImage' alt="Product" />
            </div>
            <div className="text-center blog-right" >
              <h1 className=''>{productdata?.title}</h1>
              <p className='blog-p' dangerouslySetInnerHTML={{ __html: productdata?.content }}></p>
              <p className="blog-pp">
                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.
              </p>
              <button><Link style={{textDecoration:'none', color:'white'}}  to="/blog">More Blogs</Link></button>
            </div>
          </div>
        </div>
      </div>
      <Shipping />
      <Footer />
    </>
  )
}

export default BlogDetails