import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar';
import Shipping from './Home/Shipping';
import Footer from '../Components/Footer';

function ReturnPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navbar />
            <br/>
            <br/>
            <br/>
            <br/>
            <>
                <div className="container mt-1">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='text-center mt-5 mb-5' style={{ fontWeight: '700' }}>RETURN AND EXCHANGE POLICY:</h2>
                            <h4 className='mt-3 mb-3'>Your purchase is eligible for return/exchange only if it meets the following criteria:</h4>
                            <p className='mt-3 mb-3'>Our return & exchange policy is applicable within 2 days from receipt of the product.</p>
                            <p className='mt-3 mb-3'>And applicable only for the following reasons: Damaged product, genuine manufacturing defect & incorrect product. The time frame starts from the date the product was delivered as per the confirmation received from our logistics team or courier partners.</p>
                            <p className='mt-3 mb-3'>You can reach us at <a href="mailto:support@shopzabelo.com">support@shopzabelo.com</a> or whats-app us at +91-9216989165 (11AM - 9PM) to initiate a return.</p>
                            <p className='mt-3 mb-3'>All our products are handmade by artisans, one at time, making each piece unique, due to which slight variation may occur. Minor defects will not be applicable for a return/refund.</p>
                            <p className='mt-3 mb-3'>You will be required to send us images and videos of the defect on <a href="mailto:support@shopzabelo.com">support@shopzabelo.com</a> and our team will review the images and process it accordingly.</p>
                            <h4 className='mt-3 mb-3'>Your refund amount can be issued as</h4>
                            <p>A. a store credit which can be utilized for the next purchase.</p>
                            <p>B.	Or in your bank account.</p>
                        </div>
                    </div>
                </div>
            </>
            <Shipping />
            <Footer />
        </>
    )
}

export default ReturnPolicy