import React, { useEffect, useState } from "react";
import { API_URL } from "../Url";
import axios from "axios";

function ExclusiveOffer() {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const Get_All_banner = async () => {
    try {
      const response = await axios.get(`${API_URL}/banner2/all`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log(response.data.data.banners, "sss");
      setBanners(response.data.data.banners);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    Get_All_banner();
  }, []);
  if (banners?.length == 0) {
    return null;
  }
  return (
    <>
      <div className="ex-section">
        <a
          href={banners[0]?.link}
          target="_blank"
          style={{ textDecoration: "none", color: "white" }}
        >
          <div
            className="exclusive"
            style={{
              backgroundImage: `url(${banners[0]?.bannerImage?.url})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: 'center',
            }}
          >
            <div className="blur " data-aos="zoom-out">
              <h1>Exclusive Offers</h1>
              <p>
                Apply Code 123 and get 30 % off on entire bags range valid till
                limited period
              </p>
              <div className="text-center">
                <button data-aos="zoom-in">Shop Now</button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}

export default ExclusiveOffer;
