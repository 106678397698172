import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import img from "../Assets/download.png";
import Footer from "../Components/Footer";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import { SyncLoader } from "react-spinners";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "./Url";
import Loader from "../Loader/Loader";
import { useMain } from "../Hooks/useMain";
import { Col, Row } from "react-bootstrap";

function Cart() {
  const [userdata, setUserdata] = useState([]);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [method, setMethod] = useState();
  const [Addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { handleCartChange } = useMain();
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [Cartdata, setCartdata] = useState([]);
  const [loadingCart, setLoadingCart] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [Coupon_code, setCoupon_code] = useState("");
  const [Coupon, setCoupon] = useState({});

  // console.log(selectedAddress)
  const Get_User_Profile = async () => {
    try {
      const UserID = localStorage.getItem("UserID");
      const response = await axios.get(`${API_URL}/user/getuser/${UserID}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setUserdata(response?.data?.data);
      setAddresses(response?.data?.data?.shippingAddress);
      setSelectedAddress(response?.data?.data?.shippingAddress[0]);
      setLoadingUserData(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoadingUserData(false);
    }
  };

  useEffect(() => {
    Get_User_Profile();
  }, []);

  const removeaddress = async (addressid) => {
    console.log(addressid, "aaa");
    const token = localStorage.getItem("UserToken");
    try {
      const response = await axios.get(
        `${API_URL}/user/address/update/${addressid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      Get_User_Profile();
    } catch (error) {
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const Get_User_Cart = async () => {
    setLoading(true);
    try {
      const Token = localStorage.getItem("UserToken");
      const response = await axios.get(`${API_URL}/user/cart`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Token}`,
        },
      });
      setCartdata(response.data.data.cart);
      setLoadingCart(false);
    } catch (error) {
      console.error("Error fetching cart data:", error);
      setLoadingCart(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    Get_User_Cart();
  }, []);

  useEffect(() => {
    if (!loadingCart && Cartdata?.products?.length > 0) {
      const calculatedTotal = Cartdata.products.reduce(
        (total, cartItem) => total + cartItem.varientPrice * cartItem.quantity,
        0
      );

      setTotalAmount(calculatedTotal);

      if (appliedCoupon) {
        if (calculatedTotal < appliedCoupon.min_price) {
          setAppliedCoupon(null);
          setDiscountedAmount(0);
          toast.info("Coupon removed due to item removal.", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          const discountAmount =
            (calculatedTotal * appliedCoupon.discount_percent) / 100;
          setDiscountedAmount(discountAmount);
        }
      }
    }
  }, [loadingCart, Cartdata, appliedCoupon]);

  const DeleteFromcartlist = async (ProdcutId, type, cartItem) => {
    console.log(cartItem, "aaa");
    setLoading(true);
    const token = localStorage.getItem("UserToken");
    // const type = 'subtract'
    try {
      const response = await axios.post(
        `${API_URL}/user/cart/product/${ProdcutId}/${type}`,
        { variant: cartItem?.variant, varientPrice: cartItem?.varientPrice },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        handleCartChange(response?.data?.data?.cart?.products?.length || 0);
      }
      Get_User_Cart();
    } catch (error) {
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // alert(error.response.data.error.message)
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  //==========================[ add coupon code ]======================//

  const Get_coupon_datails = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${API_URL}/coupon/${Coupon_code}/get`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const couponData = response?.data?.data?.coupon;
      if (couponData.is_active && totalAmount >= couponData.min_price) {
        const discountAmount =
          (totalAmount * couponData.discount_percent) / 100;
        setAppliedCoupon(couponData);
        setDiscountedAmount(discountAmount);
        toast.success("Coupon Applied", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(
          `Selected Item should be more than ${couponData.min_price} for the Coupon`,
          {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    } catch (error) {
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error fetching cart data:", error);
    }
  };

  const PlaceOrder = async () => {
    if (selectedAddress == null || selectedAddress == "" || !selectedAddress) {
      return toast.error("Please Select Address First", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (Addresses?.length === 0) {
      toast.success("Please Add Address First", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (method === undefined) {
      toast.success("Please Select payment Method", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (method === "cod") {
      try {
        const products = Cartdata.products.map((cartItem) => ({
          product: cartItem.product._id,
          quantity: cartItem.quantity,
          variant: cartItem.variant,
          varientPrice: cartItem.varientPrice,
        }));

        const orderData = {
          products,
          shippingAddress: selectedAddress,
          // shippingAddress: {
          //   address: Addresses[0]?.address,
          //   pincode: Addresses[0]?.pincode,
          // },
          payment_mode: "COD",
          payment_status: "PENDING",
          order_price: (totalAmount - (discountedAmount ?? 0)).toFixed(2),
        };
        console.log(orderData, "aaa");

        const Token = localStorage.getItem("UserToken");

        const response = await axios.post(
          `${API_URL}/user/order/place`,
          orderData,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Token}`,
            },
          }
        );
        if (response.status === 200) {
          Get_User_Cart();
          setTotalAmount(0);
          setDiscountedAmount(0);
          toast.success("Order placed successfully!", { autoClose: 1000 });
        }
      } catch (error) {
        console.error("Error placing order:", error);
        toast.error("Error placing order", { autoClose: 1000 });
      }
    } else if (method === "online") {
      const products = Cartdata.products.map((cartItem) => ({
        product: cartItem.product._id,
        quantity: cartItem.quantity,
        variant: cartItem.variant,
        varientPrice: cartItem.varientPrice,
      }));

      const orderData = {
        products,
        shippingAddress: selectedAddress,
        // shippingAddress: {
        //   address: Addresses[0]?.address,
        //   pincode: Addresses[0]?.pincode,
        // },
        order_price: (totalAmount - (discountedAmount ?? 0)).toFixed(2),
        coupon_applied: null,
      };
      console.log(orderData, "aaa");

      const savedOrder = await fetchCreateCC_Order(orderData);
      console.log(savedOrder);

      if (savedOrder?.status == "success") {
        try {
          const res = await axios.post(
            `https://zebelo-backend.vercel.app/phonepe1/payment`,
            {
              name: userdata?.displayName,
              amount: (totalAmount - (discountedAmount ?? 0))
                .toFixed(2)
                .toString(),
              number: userdata?.phoneNumber,
              merchantUserId: savedOrder?.data?.order?._id,
            }
          );
          console.log(res, "bbbbb");
          window.location.href = res.data;
        } catch (error) {
          console.log(error);
        }
      }

      // toast.success("order online place Succefull", {
      //   position: "top-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };

  const fetchCreateCC_Order = async (data) => {
    console.log(data);
    try {
      const Token = localStorage.getItem("UserToken");
      const response = await fetch(`${API_URL}/ccavenue-createOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Token}`,
        },
        body: JSON.stringify(data),
        merchant_id: "2513570",
        access_code: "AVIK77KF61BD04KIDB",
      });
      const resData = await response?.json();
      console.log(
        "create cc order data=================================================>",
        resData
      );
      return resData;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navbar />
      {loading && <Loader />}
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="cart">
        <div className="cartnav">
          <h3>
            Home <IoIosArrowForward /> Shop <IoIosArrowForward /> Add To Cart
          </h3>
        </div>
        <br/>
        
        <>
          <>
            <div className="table-responsive">
              <table className="table tablefont">
                <thead
                  className="table-dark tablehi"
                  style={{ verticalAlign: "middle", textAlign: "center" }}
                >
                  <tr>
                    <th scope="col">Product Detail</th>
                    <th scope="col">Varient</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                    {/* <th scope="col">Shipping</th> */}
                    <th scope="col">SubTotal</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    height: "160px",
                    verticalAlign: "middle",
                    textAlign: "center",
                  }}
                >
                  {loadingCart ? (
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                      }}
                    >
                      <span class="loader"></span>
                    </div>
                  ) : Cartdata?.products?.length > 0 ? (
                    Cartdata.products.map((cartItem) => (
                      <tr
                        key={cartItem?.product?._id}
                        style={{ height: "100px" }}
                      >
                        <td style={{ width: "350px", marginLeft: "30px" }}>
                          <img
                            src={cartItem?.product?.displayImage[0]?.url || ""}
                            alt={cartItem?.product?.displayName}
                            style={{
                              width: "50px",
                              height: "50px",
                              marginRight: "10px",
                              borderRadius: "10px",
                            }}
                          />
                          {cartItem?.product?.displayName?.slice(0, 10)}
                        </td>
                        <td>{cartItem?.variant}</td>
                        <td>Rs. {cartItem?.varientPrice}</td>
                        <td>
                          {" "}
                          <button
                            onClick={() =>
                              DeleteFromcartlist(
                                cartItem?.product?._id,
                                "subtract",
                                cartItem
                              )
                            }
                            style={{
                              padding: "0px 15px",
                              marginRight: "5px",
                              borderRadius: "10px",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            -
                          </button>
                          {cartItem.quantity}
                          <button
                            onClick={() =>
                              DeleteFromcartlist(
                                cartItem?.product?._id,
                                "add",
                                cartItem
                              )
                            }
                            style={{
                              padding: "0px 15px",
                              marginLeft: "5px",
                              borderRadius: "10px",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            +
                          </button>
                        </td>
                        {/* <td>Free</td> */}
                        <td>
                          Rs.{" "}
                          {(
                            cartItem?.varientPrice * cartItem?.quantity
                          ).toFixed(2)}
                        </td>
                        <td
                          onClick={() =>
                            DeleteFromcartlist(
                              cartItem?.product?._id,
                              "delete",
                              cartItem
                            )
                          }
                        >
                          <MdDelete style={{ fontSize: "24px" }} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No items in the cart</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="cart-check">
              <div className="row">
                <div className="col-sm-4">
                  <div className="">
                    <h5 className="cart-dis">Discount Codes</h5>
                    <p className="cart-text">
                      Enter your coupon code if you have one
                    </p>
                    <div className="coupon-box">
                      <input
                        type="text"
                        placeholder="Enter Coupon"
                        value={Coupon_code}
                        onChange={(e) => setCoupon_code(e.target.value)}
                      />
                      <button
                        className="apply-btn"
                        onClick={(e) => {
                          Get_coupon_datails(e);
                        }}
                      >
                        Apply Coupon
                      </button>
                    </div>
                    {/* <div>
                      <input type="text" />
                    </div>
                    <div className="mt-3">
                      <button
                        style={{ backgroundColor: "#001963", color: "white" }}
                        className="btn "
                      >
                        Apply Coupon
                      </button>
                    </div> */}
                    <div className="mt-4">
                      <button type="submit" className="place-order-btn ">
                        Continue Shopping
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3"></div>
                <div className="col-sm-4 totalpad">
                  {Addresses?.length > 0 ? (
                    Addresses?.map((Address) => (
                      <div className="card mb-2" style={{ width: "100%" }}>
                        <div className="card-body">
                          <input
                            type="radio"
                            name="addressRadio"
                            id={`addressRadio${Address?._id}`}
                            checked={selectedAddress?._id === Address?._id}
                            onChange={() => setSelectedAddress(Address)}
                          />
                          <h5 className="card-title">Address</h5>
                          <Row>
                            <Col>
                              <h6 className="card-subtitle mb-2 text-muted">
                                {Address?.name}
                              </h6>
                            </Col>
                            <Col>
                              <p className="card-subtitle mb-2 text-muted">
                                {Address?.email}
                              </p>
                            </Col>
                          </Row>
                          <p className="card-text">{Address?.address}</p>
                          <Row>
                            <Col>
                              <p className="card-text">{Address?.city} </p>
                            </Col>
                            <Col>
                              <p className="card-text">{Address?.state} </p>
                            </Col>
                          </Row>
                          <h6 className="card-subtitle mb-2 text-muted mt-2">
                            PinCode : {Address?.pincode}
                          </h6>
                          <button
                            onClick={() => {
                              removeaddress(Address?._id);
                            }}
                            style={{ backgroundColor: "#4e2985" }}
                            className="Add-address-btn btn mt-3"
                          >
                            <text style={{ fontSize: "14px", color: "white" }}>
                              Remove Address
                            </text>
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <h2 className="cart-dis">No Address added yet</h2>
                    </div>
                  )}
                  <p className="itemselected coupon-text">
                    Total items selected (
                    {Cartdata.products && Cartdata.products.length
                      ? Cartdata.products.length
                      : 0}
                    items)
                  </p>
                  <div className="totalMRP coupon-text">
                    <p className="coupon-text">Total MRP</p>
                    <p className="coupon-text">
                      <strong>Rs. {totalAmount.toFixed(2)}</strong>
                    </p>
                  </div>
                  {appliedCoupon ? (
                    <>
                      <div className="discount coupon-text">
                        <p className="coupon-text">{`Discount (${appliedCoupon.code})`}</p>
                        <p
                          className="coupon-text"
                          style={{ color: "#C02026" }}
                        >{`-${discountedAmount.toFixed(2)}`}</p>
                      </div>
                      <div className="totalMRP">
                        <p>
                          <strong>Total Amount</strong>
                        </p>
                        <p>
                          <strong>
                            Rs. {(totalAmount - discountedAmount).toFixed(2)}
                          </strong>
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="discount coupon-text">
                        <p className="coupon-text">Discount</p>
                        <p className="coupon-text" style={{ color: "#C02026" }}>
                          -00
                        </p>
                      </div>
                      <div className="totalMRP">
                        <p className="coupon-text">Convenience fees</p>
                        <p className="coupon-text">0.00</p>
                      </div>
                      <div className="billline"></div>
                      <br />
                      <div className="totalMRP">
                        <p className="coupon-text">
                          <strong className="coupon-text">Total Amount</strong>
                        </p>
                        <p className="coupon-text">
                          <strong className="coupon-text">
                            Rs. {totalAmount.toFixed(2)}
                          </strong>
                        </p>
                      </div>
                    </>
                  )}
                  <button
                    onClick={() => {
                      PlaceOrder();
                    }}
                    className="place-order-btn "
                  >
                    Place Order
                  </button>
                </div>
              </div>
              <div
                className="profile12"
                style={{ marginTop: "20px" }}
              >
                <h2 className="font-semibold cart-dis text-[12px] md:text-[12px] l:text-[15px] xl:text-[18px] mt-2 mb-1">
                  Payment Method
                </h2>
                <div style={{ marginTop: "20px" }} className="d-flex flex-column flex-md-row">
                  <button
                    className="place-order-btn"
                    style={{
                      padding: "1rem 0.5rem",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      width: "15rem",
                      // backgroundColor: "white",
                    }}
                    onClick={async (e) => {
                      setMethod("online");
                    }}
                  >
                    <input
                      type="radio"
                      value="online"
                      checked={method == "online"}
                    />
                    &nbsp; Online
                  </button>{" "}
                  &nbsp;&nbsp;
                  <button
                    className="place-order-btn"
                    style={{
                      padding: "1rem 0.5rem",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      width: "15rem",
                      // backgroundColor: "white",
                    }}
                    onClick={async (e) => {
                      setMethod("cod");
                    }}
                  >
                    <input type="radio" value="cod" checked={method == "cod"} />
                    &nbsp; Cash On Delivery
                  </button>
                </div>
              </div>
            </div>
          </>
        </>
      </div>
      <hr />
      <Footer />
    </>
  );
}

export default Cart;
