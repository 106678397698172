import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import sucess from '../Assets/payment-successful-5343612-4475257.webp'
function PaymentSucessful() {
  return (
   <>
    <Navbar/>
    <div className='failed'>
       <img src={sucess}/>
        <h1>Payment Sucessful</h1>
        <button>Back To Home</button>

       </div>

    <Footer/>
   </>
  )
}

export default PaymentSucessful