import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "./Url";
import Shipping from "./Home/Shipping";
import { Modal, Form, Button } from "react-bootstrap";
import { FaRegUser } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaRegHeart } from "react-icons/fa";
import orderImg from "../Assets/profile/order-img.svg";
import heartImg from "../Assets/profile/heart-img.svg";
import userImg from "../Assets/profile/user-img.svg";
import logImg from "../Assets/profile/sign-out.svg";

function Profile() {
  const navigate = useNavigate();
  const [userdata, setUserdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const Get_User_Profile = async () => {
    try {
      const UserID = localStorage.getItem("UserID");
      const response = await axios.get(`${API_URL}/user/getuser/${UserID}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setUserdata(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    Get_User_Profile();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  const [newPincode, setNewPincode] = useState("");
  const [isDefault, setIsDefault] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleAddAddress = async () => {
    try {
      const token = localStorage.getItem("UserToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${API_URL}/user/address/update`,
        {
          address: newAddress,
          pincode: newPincode,
          isDefault: isDefault,
        },
        config
      );
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(response.data);
      setNewAddress("");
      setNewPincode("");
      setIsDefault(false);
      Get_User_Profile();
      closeModal();
    } catch (error) {
      console.log(error.response.data.error.message);
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const removeaddress = async (addressid) => {
    console.log(addressid, "aaa");
    const token = localStorage.getItem("UserToken");
    try {
      const response = await axios.get(
        `${API_URL}/user/address/update/${addressid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      Get_User_Profile();
    } catch (error) {
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const Logout = () => {
    toast.success("Logout Succesfull.", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <Navbar />
      <div className="cartnav">
        <h3>
          Home <IoIosArrowForward /> My Account
        </h3>
      </div>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <span class="loader"></span>
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-sm-12 col-md-4 mt-5 profileFont"
                style={{ paddingLeft: "50px" }}
              >
                <div className="pro">
                  <div className="profile-img mx-1">
                    <img
                      width={70}
                      height={70}
                      src={userdata?.displayImage?.url}
                    />
                  </div>
                  <div
                    className="profile-img"
                    style={{ marginTop: "5px", marginLeft: "2px" }}
                  >
                    <h2 style={{ fontWeight: "600" }}>
                      {userdata.displayName}
                    </h2>
                    <p>Welcome to your Account</p>
                  </div>
                </div>
                <div className="newul">
                  <ul className="ulsss iconleft mt-2">
                    <div className="mt-2">
                      <li>
                        <Link className="link" to="/cart">
                          <img src={orderImg} alt="" />
                          <text className="icontext"> My Cart </text>{" "}
                        </Link>
                      </li>
                    </div>
                    <div className="mt-2">
                      <li>
                        <Link className="link" to="/Wishlist">
                          {/* <FaRegHeart className="iconisize" />{" "} */}
                          <img src={heartImg} alt="" />
                          <text className="icontext"> Wish list </text>{" "}
                        </Link>
                      </li>
                    </div>
                    <div className="mt-2">
                      <li>
                        <Link className="link" to="/blog">
                          <img src={userImg} alt="" />
                          {/* <FaRegUser className="iconisize" /> */}
                          <text className="icontext">My Info </text>
                        </Link>
                      </li>
                    </div>
                    <div className="mt-2">
                      <li
                        onClick={() => {
                          Logout();
                        }}
                      >
                        <Link className="link">
                          {/* <LuLogOut className="iconisize" /> */}
                          <img src={logImg} alt="" />
                          <text className="icontext"> Sign out </text>{" "}
                        </Link>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-8 mt-5"
                style={{ paddingLeft: "10px" }}
              >
                <div className="px-2" style={{ width: "95%" }}>
                  <h1 className="profile-rightnew mt-1">My Info</h1>
                  <p className="profile-rightp mt-1">Contact Details</p>
                  <div className="myinfo">
                    <div className="name">
                      <h3>Your Name</h3>
                      <p>{userdata.displayName}</p>
                    </div>
                  </div>
                  <div className="myinfo">
                    <div className="name">
                      <h3>Your Email</h3>
                      <p>{userdata.email}</p>
                    </div>
                  </div>
                  <div className="myinfo">
                    <div className="name">
                      <h3>Your Phone Number</h3>
                      <p>{userdata.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="myinfo">
                    <div className="name">
                      <h3>Password</h3>
                      <input type="password" value={userdata.password} style={{border: "none", backgroundColor: "transparent"}} />
                    </div>
                  </div>
                  <div>
                  <br/>
                  <br/>
                    <div className="">
                      <div className="name">
                        <h4>Address</h4>
                      </div>
                      {userdata.shippingAddress && (
                        <>
                          <button
                            onClick={openModal}
                            style={{
                              backgroundColor: "#4e2985",
                              marginLeft: "100px",
                            }}
                            className="btn btn-primary"
                          >
                            {userdata.shippingAddress
                              ? "Manage Addresses"
                              : "Add New"}
                          </button>
                          <div>
                            <Modal show={isModalOpen} onHide={closeModal}>
                              <Modal.Header closeButton>
                                <Modal.Title className="mt-2">
                                  Add New Address
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body
                                style={{
                                  paddingLeft: "30px",
                                  paddingTop: "10px",
                                }}
                              >
                                <Form>
                                  <Form.Group
                                    className="mt-2"
                                    controlId="formNewAddress"
                                  >
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter new address"
                                      value={newAddress}
                                      onChange={(e) =>
                                        setNewAddress(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mt-4"
                                    controlId="formNewPincode"
                                  >
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter pincode"
                                      value={newPincode}
                                      onChange={(e) =>
                                        setNewPincode(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    controlId="formIsDefault"
                                    className="mt-4"
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      label="Set as default address"
                                      checked={isDefault}
                                      onChange={() => setIsDefault(!isDefault)}
                                    />
                                  </Form.Group>
                                </Form>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={closeModal}
                                >
                                  Close
                                </Button>
                                <Button
                                  style={{ backgroundColor: "#4e2985" }}
                                  variant="primary"
                                  onClick={handleAddAddress}
                                >
                                  Add Address
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      )}
                    </div>
                    <br />
                    {userdata.shippingAddress ? (
                      <div className="row">
                        {userdata.shippingAddress.map((address) => (
                          <div
                            key={address._id}
                            className="newadd-card col-sm-5 mx-2 "
                            style={{ marginTop: "20px", paddingLeft: "40px" }}
                          >
                            <p>{address.address}</p>
                            <p>{address.pincode}</p>
                            <div>
                              <button className="default">
                                {address.isDefault
                                  ? "Default billing address"
                                  : "Set as default"}
                              </button>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <button
                                className="home"
                                onClick={() => {
                                  removeaddress(address._id);
                                }}
                              >
                                Remove
                              </button>
                              <button className="default">Edit</button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={() => console.log("Add address clicked")}
                      >
                        Add Address
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <Shipping />
        </>
      )}
      <Footer />
    </>
  );
}

export default Profile;
