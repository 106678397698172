import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img1 from '../Assets/Mask group.png'
import Shipping from './Home/Shipping';


function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div class="about" data-aos="zoom-in-up">
        <div class="inner-section" >
          <h1>About Us</h1>
          <p class="text">
            India’s first exclusive Handbags marketplace.
            Zabelo was Launched in 2023 with the sole purpose of providing fashionable structured bags with a wide Varity of vibrant colors in the industry where you could find only monotonous designs. The main agenda was to make an ethical brand which provides both Ethnic and Modern pattern Bags with wide varieties for multiple purposes.
          </p>
          <p class="text">
          Zabelo is a line of designer handcrafted vegan bags and accessories for active and on the go lifestyle. Each bag is Handcrafted by skilled artisans using premium quality materials with ultimate love.
          </p>
          <div class="skills">
            <button data-aos="zoom-in">Contact Us</button>
          </div>
        </div>
      </div>
    <Shipping/>
      <Footer />
    </>
  )
}

export default AboutUs