import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "../Url";
import Dim from "../../Assets/Screenshot 2023-10-13 110103.png";
import { SyncLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import right from "../../Assets/right-explore.png";
import Slider from "react-slick";
import leftArrow from "../../Assets/left-arrow.png";
import rightArrow from "../../Assets/right-arrow.png";

function ShopByStyle() {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
    ]
  };
  const navigate = useNavigate();
  const [Category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const Get_All_categerys = async () => {
    try {
      const response = await axios.get(`${API_URL}/product/category/all`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setCategory(response.data.data.categories);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    Get_All_categerys();
  }, []);

  return (
    <div className="Shopbystyle" style={{ marginBottom: "50px" }}>
      <div className="shop-container">
        <div className="shopby-heading d-flex justify-content-between align-items-center" style={{marginBottom: '50px'}}>
          <h1 className="shopbt">Shop By Category</h1>
          <div style={{display: 'flex', gap: "20px", alignItems: 'center' }}>
            <button
              className="button"
              onClick={previous}
              style={{ border: "none", background: "transparent" }}
            >
              <img src={leftArrow} alt="" />
            </button>
            <button
              className="button"
              onClick={next}
              style={{ border: "none", background: "transparent" }}
            >
              <img src={rightArrow} alt="" />
            </button>
          </div>
        </div>
        <Slider
          {...settings}
          ref={(slider) => {
            sliderRef = slider;
          }}
        >
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <span class="loader"></span>
            </div>
          ) : (
            Category.map((category, index) => (
              <div>
                <div
                  className="shop-card"
                  key={index}
                  style={{
                    backgroundImage: `url(${category?.displayImage?.url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  onClick={() => navigate(`/category/${encodeURIComponent(category?.name)}/${category._id}`)}
                >
                  <button className="shop-btn">New</button>
                  <div className="shop-content">
                    <h6 className="shop-title">{category?.name}</h6>
                    <p className="shop-text">
                      Explore now <img src={right} alt="" />
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </Slider>
      </div>
    </div>
  );
}

export default ShopByStyle;
