import React, { useEffect, useState } from "react";
import logo from "../Assets/image 68.png";
import Zabelologo from "../Assets/Zabelologo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { LuLogIn } from "react-icons/lu";
import LoginModal from "../Pages/LoginModal/LoginModal";
import { Button, Modal } from "react-bootstrap";
import { useMain } from "../Hooks/useMain";
import axios from "axios";
import { API_URL } from "../Pages/Url";
import searchImg from "../Assets/search-btn.png";
import wishList from "../Assets/wishList.png";
import profileImg from "../Assets/profileImg.png";
import cartImg from "../Assets/cartImg.png";
import SlidingText from "./SlidingText";

function Navbar() {
  const navigate = useNavigate();
  const [isListOpen, setListOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [shortHead, setShortHead] = useState([]);
  const { wishlistCount, cartCount, handleWishlistChange, handleCartChange } =
    useMain();

  console.log(shortHead);
  const getAllHead = async () => {
    try {
      const res = await axios.get(`${API_URL}/heading/all`);
      if (res?.status == 200) {
        setShortHead(res?.data?.data?.headings);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllHead();
  }, []);
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
  };

  const handleSuggestionClick = (item) => {
    // console.log("Navigating to:", `/ProductDetail/${item.slug}`);
    navigate(`/ProductDetail/${encodeURIComponent(item?.displayName)}/${item._id}`, {
      state: {
        item: item,
      },
    });
    setSearchInput("");
    setSearchSuggestions([]);
  };

  const ProductClick = (item) => {};

  useEffect(() => {
    const fetchSearchSuggestions = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/products/searchproduct?query=${searchInput}`
        );
        const suggestions = response.data.data;
        console.log(suggestions);
        setSearchSuggestions(suggestions);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    };

    const timerId = setTimeout(() => {
      if (searchInput.trim() !== "") {
        fetchSearchSuggestions();
      }
    }, 1000); // 2000 milliseconds = 2 seconds

    return () => clearTimeout(timerId);
  }, [searchInput]);

  const Get_User_Cart = async (Token) => {
    try {
      const response = await axios.get(`${API_URL}/user/cart`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Token}`,
        },
      });
      // console.log(response?.data?.data?.cart )
      if (response.status == 200) {
        handleCartChange(response?.data?.data?.cart?.products?.length || 0);
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const Get_User_Wishlist = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/wishlist/getwishlist`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        handleWishlistChange(response?.data?.data?.products?.length || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const toggleList = () => {
    setListOpen((prev) => !prev);
  };

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const userToken = localStorage.getItem("UserToken");

  useEffect(() => {
    const Token = localStorage.getItem("UserToken");
    if (Token) {
      Get_User_Cart(Token);
      Get_User_Wishlist(Token);
    } else {
      const wishlist = JSON.parse(localStorage.getItem("Wishlist"));
      const cart = JSON.parse(localStorage.getItem("cart"));
      if (wishlist) {
        handleWishlistChange(wishlist?.length);
      }
      if (cart) {
        handleCartChange(cart?.length);
      }
    }
  }, []);

  return (
    <>
      {shortHead.length > 0 && <SlidingText texts={shortHead} />}
      <div className="fix-header" style={{top:shortHead?.length>0?"30px":"0px", marginBottom:shortHead?.length>0?"30px":"0px"}}>
        {/* <div className="top-line">
        Copyright © 2022 pretty stonese All Rights Reserved.
      </div> */}
        <div className="top-header">
          <NavLink className="link desktop-logo" to="/">
            <img src={Zabelologo} height={100} width={100} alt="Logo" />
          </NavLink>
          <nav>
            <NavLink className="link mobile-logo">
              <img src={Zabelologo} height={70} width={70} alt="Logo" />
            </NavLink>
            <div
              className="overlay"
              style={{ left: isListOpen ? 0 : "100%" }}
              onClick={() => setListOpen(false)}
            ></div>

            <ul
              className={`lisst`}
              style={{ left: isListOpen ? "0px" : "-300px" }}
            >
              <li>
                <NavLink className="link" to="/" exact activeClassName="active">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink className="link" to="/shop" activeClassName="active">
                  Shop
                </NavLink>
              </li>
              <li>
                <NavLink className="link" to="/blog" activeClassName="active">
                  Blog
                </NavLink>
              </li>
              <li>
                <NavLink className="link" to="/about" activeClassName="active">
                  About Us
                </NavLink>
              </li>
              <div className="icons-mobile">
                {userToken ? (
                  <NavLink
                    className="link"
                    to="/Wishlist"
                    activeClassName="active"
                  >
                    <FaRegHeart
                      style={{ marginLeft: "10px", fontSize: "24px" }}
                    />
                    <div
                      style={{
                        position: "relative",
                        left: "2rem",
                        bottom: "2.9rem",
                        backgroundColor: "#e9ecef",
                        padding: "3px",
                        borderRadius: "50%",
                        width: "25px",
                        textAlign: "center",
                      }}
                    >
                      <span>{wishlistCount}</span>
                    </div>
                  </NavLink>
                ) : (
                  <NavLink
                    className="link"
                    to="/wishlists"
                    style={{ position: "relative" }}
                    activeClassName="active"
                  >
                    {/* <FaRegHeart style={{ marginLeft: "10px", fontSize: "24px" }} /> */}
                    <img src={wishList} alt="" />
                    <div
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "-24px",
                        backgroundColor: "#e9ecef",
                        padding: "3px",
                        borderRadius: "50%",
                        width: "25px",
                        textAlign: "center",
                      }}
                    >
                      <span>{wishlistCount}</span>
                    </div>
                  </NavLink>
                )}
                {userToken ? (
                  <NavLink
                    className="link"
                    to="/Cart"
                    style={{ position: "relative" }}
                    activeClassName="active"
                  >
                    {/* <AiOutlineShoppingCart
                  style={{ marginLeft: "20px", fontSize: "28px" }}
                /> */}
                    <img src={cartImg} alt="" />
                    <div
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "-24px",
                        backgroundColor: "#e9ecef",
                        padding: "3px",
                        borderRadius: "50%",
                        width: "25px",
                        textAlign: "center",
                      }}
                    >
                      <span>{cartCount}</span>
                    </div>
                  </NavLink>
                ) : (
                  <NavLink
                    className="link"
                    to="/carts"
                    style={{ position: "relative" }}
                  >
                    {/* <AiOutlineShoppingCart
                  style={{ marginLeft: "20px", fontSize: "28px" }}
                /> */}
                    <img src={cartImg} alt="" />
                    <div
                      style={{
                        position: "absolute",
                        right: "0",
                        top: "-24px",
                        backgroundColor: "#e9ecef",
                        padding: "3px",
                        borderRadius: "50%",
                        width: "25px",
                        textAlign: "center",
                      }}
                    >
                      <span>{cartCount}</span>
                    </div>
                  </NavLink>
                )}

                {userToken ? (
                  <NavLink
                    className="link"
                    to="/profile"
                    activeClassName="active"
                  >
                    {/* <FaRegUser style={{ marginLeft: "20px", fontSize: "24px" }} /> */}
                    <img src={profileImg} alt="" />
                  </NavLink>
                ) : (
                  <NavLink className="link" onClick={toggleModal}>
                    {/* <LuLogIn style={{ marginLeft: "20px", fontSize: "24px" }} /> */}
                    <img src={profileImg} alt="" />
                  </NavLink>
                )}
              </div>
            </ul>

            <button onClick={toggleList} className="bras-btn">
              <i className="bi bi-list"></i>
            </button>
            {/* <input
          type="checkbox"
          id="check"
          checked={isListOpen}
          onChange={toggleList}
        />
        <label htmlFor="check" className="checkbtn">
        </label> */}
            <Modal
              show={isModalOpen}
              onHide={toggleModal}
              size="lg"
              className="mt-2"
            >
              <Modal.Body>
                <div>
                  <LoginModal />
                </div>
              </Modal.Body>
            </Modal>
          </nav>
          <div>
            <div className="icons">
              <div
                className="desktop-search"
                style={{
                  width: open ? "250px" : "auto",
                  alignItems: "center",
                  gap: "18px",
                }}
              >
                <img src={searchImg} alt="" onClick={() => setOpen(!open)} />
                {open && (
                  <input
                    type="text"
                    placeholder="What are you looking for?"
                    value={searchInput}
                    onChange={handleInputChange}
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                      border: "1px solid #25064d",
                      outline: "1px solid #25064d",
                      background: "#f6f6f6",
                      padding: "10px 20px",
                    }}
                  />
                )}
                {open && searchInput?.length > 0 && (
                  <ul
                    className="search-suggestions"
                    style={{
                      // display: "flex",
                      // flexDirection: "column",
                      // listStyleType: "none",
                      // padding: "14px 20px",
                      position: "absolute",
                      top: "48px",
                      left: "0",
                      zIndex: 1,
                      // gap: 0,
                      // backgroundColor: "wheat",
                    }}
                  >
                    {searchSuggestions.map((suggestion) => (
                      <li
                        key={suggestion._id}
                        style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          margin: 0,
                        }}
                        onClick={() => {
                          handleSuggestionClick(suggestion);
                        }}
                      >
                        {/* <Link
                      // to={`/ProductDetail/${suggestion?.displayName}`}
                      onClick={() => {
                        handleSuggestionClick(suggestion)
                        // ProductClick(suggestion)
                      }}
                      style={{ textDecoration: "none", color: "inherit" }}
                    > */}
                        {suggestion.displayName}
                        {/* </Link> */}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {userToken ? (
                <NavLink
                  className="link"
                  to="/wishlists"
                  style={{ position: "relative" }}
                  activeClassName="active"
                >
                  {/* <FaRegHeart style={{ marginLeft: "10px", fontSize: "24px" }} /> */}
                  <img src={wishList} alt="" />
                  <div
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "-24px",
                      backgroundColor: "#e9ecef",
                      padding: "3px",
                      borderRadius: "50%",
                      width: "25px",
                      textAlign: "center",
                    }}
                  >
                    <span>{wishlistCount}</span>
                  </div>
                </NavLink>
              ) : (
                <NavLink
                  className="link"
                  to="/wishlists"
                  style={{ position: "relative" }}
                  activeClassName="active"
                >
                  {/* <FaRegHeart style={{ marginLeft: "10px", fontSize: "24px" }} /> */}
                  <img src={wishList} alt="" />
                  <div
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "-24px",
                      backgroundColor: "#e9ecef",
                      padding: "3px",
                      borderRadius: "50%",
                      width: "25px",
                      textAlign: "center",
                    }}
                  >
                    <span>{wishlistCount}</span>
                  </div>
                </NavLink>
              )}
              {userToken ? (
                <NavLink
                  className="link"
                  to="/Cart"
                  style={{ position: "relative" }}
                  activeClassName="active"
                >
                  {/* <AiOutlineShoppingCart
                  style={{ marginLeft: "20px", fontSize: "28px" }}
                /> */}
                  <img src={cartImg} alt="" />
                  <div
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "-24px",
                      backgroundColor: "#e9ecef",
                      padding: "3px",
                      borderRadius: "50%",
                      width: "25px",
                      textAlign: "center",
                    }}
                  >
                    <span>{cartCount}</span>
                  </div>
                </NavLink>
              ) : (
                <NavLink
                  className="link"
                  to="/carts"
                  style={{ position: "relative" }}
                >
                  {/* <AiOutlineShoppingCart
                  style={{ marginLeft: "20px", fontSize: "28px" }}
                /> */}
                  <img src={cartImg} alt="" />
                  <div
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "-24px",
                      backgroundColor: "#e9ecef",
                      padding: "3px",
                      borderRadius: "50%",
                      width: "25px",
                      textAlign: "center",
                    }}
                  >
                    <span>{cartCount}</span>
                  </div>
                </NavLink>
              )}

              {userToken ? (
                <NavLink
                  className="link"
                  to="/profile"
                  activeClassName="active"
                >
                  {/* <FaRegUser style={{ marginLeft: "20px", fontSize: "24px" }} /> */}
                  <img src={profileImg} alt="" />
                </NavLink>
              ) : (
                <NavLink className="link" onClick={toggleModal}>
                  {/* <LuLogIn style={{ marginLeft: "20px", fontSize: "24px" }} /> */}
                  <img src={profileImg} alt="" />
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="mobile-search"
        style={{
          width: "100%",
          alignItems: "center",
          position: "relative",
          gap: "18px",
          top: "90px",
          padding: "0 20px",
        }}
      >
        <img src={searchImg} alt="" />
        <input
          type="text"
          placeholder="What are you looking for?"
          value={searchInput}
          onChange={handleInputChange}
          style={{
            width: "100%",
            borderRadius: "5px",
            border: "1px solid #25064d",
            outline: "1px solid #25064d",
            background: "#f6f6f6",
            padding: "10px 20px",
          }}
        />
        {open && searchInput?.length > 0 && (
          <ul
            className="search-suggestions"
            style={{
              // display: "flex",
              // flexDirection: "column",
              // listStyleType: "none",
              position: "absolute",
              top: "48px",
              // padding: "14px 20px",
              zIndex: 1,
              // gap: 0,
              // backgroundColor: "wheat",
            }}
          >
            {searchSuggestions.map((suggestion) => (
              <li
                key={suggestion._id}
                style={{
                  textDecoration: "none",
                  fontSize: "14px",
                  margin: 0,
                }}
                onClick={() => {
                  handleSuggestionClick(suggestion);
                }}
              >
                {/* <Link
                      // to={`/ProductDetail/${suggestion?.displayName}`}
                      onClick={() => {
                        handleSuggestionClick(suggestion)
                        // ProductClick(suggestion)
                      }}
                      style={{ textDecoration: "none", color: "inherit" }}
                    > */}
                {suggestion.displayName}
                {/* </Link> */}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/*  old navbar  */}

      {/* <nav>
        <NavLink className='link'>
          <img src={Zabelologo} height={70} width={70} alt="Logo" />
        </NavLink>

        <ul className={`lisst ${isListOpen ? 'open' : ''}`}>
          <li><NavLink className='link' to='/' exact activeClassName="active">Home</NavLink></li>
          <li><NavLink className='link' to='/shop' activeClassName="active">Shop</NavLink></li>
          <li><NavLink className='link' to='/blog' activeClassName="active">Blog</NavLink></li>
          <li><NavLink className='link' to='/about' activeClassName="active">About Us</NavLink></li>
        </ul>

        <div>
          <div className='icons'>
            {userToken ? (
              <NavLink className='link' to='/Wishlist' activeClassName="active">
                <FaRegHeart style={{ marginLeft: '10px', fontSize: '24px' }} />
              </NavLink>
            ) : (
              <NavLink className='link' to='/wishlists' activeClassName="active" >
                <FaRegHeart style={{ marginLeft: '10px', fontSize: '24px' }} />
              </NavLink>
            )}
            {userToken ? (
              <NavLink className='link' to='/Cart' activeClassName="active">
                <AiOutlineShoppingCart style={{ marginLeft: '20px', fontSize: '28px' }} />
              </NavLink>
            ) : (
              <NavLink className='link' to='/carts'>
                <AiOutlineShoppingCart style={{ marginLeft: '20px', fontSize: '28px' }} />
              </NavLink>
            )}

            {userToken ? (
              <NavLink className='link' to='/profile' activeClassName="active">
                <FaRegUser style={{ marginLeft: '20px', fontSize: '24px' }} />
              </NavLink>
            ) : (
              <NavLink className='link' onClick={toggleModal}>
                <LuLogIn style={{ marginLeft: '20px', fontSize: '24px' }} />
              </NavLink>
            )}
          </div>
        </div>
        <input type="checkbox" id='check' checked={isListOpen} onChange={toggleList} />
        <label htmlFor='check' className='checkbtn'>
          <i className="bi bi-list"></i>
        </label>
        <Modal show={isModalOpen} onHide={toggleModal} size="lg" className='mt-2'>
          <Modal.Body >
            <div>
              <LoginModal />
            </div>
          </Modal.Body>
        </Modal>
      </nav> */}
    </>
  );
}

export default Navbar;
